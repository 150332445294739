import { Component, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-building-approval',
  templateUrl: './building-approval.component.html',
  styleUrl: './building-approval.component.css'
})
export class BuildingApprovalComponent  implements OnInit{
  assetdetail:any;
  PanchayatID:any="Manikpur";
  constructionForm: FormGroup;
  lstLevel2:any[]=[];
  selbuildapprovid:any="";
  buildingapproval:any[]=[];
  filterState: any = {};
  buildapprovalFile:any[]=[];
  private filterStateSubscription!: Subscription;
  constructor(private shareService:ShareService,private fb: FormBuilder,private panchayatservice: PanchayatService,private filterService: FilterService
    ,@Optional() @SkipSelf() public config: DynamicDialogConfig
  ) {    
    if(this.config){
      this.assetdetail=this.config?.data?.assetdata;
    }
    this.constructionForm = this.fb.group({
      ward_id: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      aadharCardNumber: ['', [Validators.required, Validators.pattern('^[0-9]{12}$')]],
      applicantName: ['', Validators.required],
      fathersHusbandsName: ['', Validators.required],
      correspondenceAddress: ['', Validators.required],
      plotNumber: ['', Validators.required],
      maxBuildingHeight: ['', Validators.required],
      plotArea: ['', Validators.required],
      constructedArea: ['', Validators.required],
      proposedConstructedArea: ['', Validators.required],
      natureOfConstruction: ['', Validators.required],
      PropertyCost: ['', Validators.required],
      buildingApprovalFee: ['', Validators.required],
      architectName: ['', Validators.required],
      architectDocument: ['', Validators.required],
      nocDocument: ['', Validators.required],
      planLayout: ['', Validators.required],
      inspectionReport: ['', Validators.required],
      approvalReport: ['', Validators.required],
      buildingapproval_status:['',Validators.required]
    });
    
  }
  selUploadedDocuments:any[]=[];
  selectrow(buildapprovdata: any): void {    
    this.selUploadedDocuments=[];
    this.selbuildapprovid = buildapprovdata.BuildingApprovalid;
    this.constructionForm.patchValue({ward_id: buildapprovdata?.ward_id}),
    this.constructionForm.patchValue({applicantName: buildapprovdata?.ApplicantName}),
    this.constructionForm.patchValue({fathersHusbandsName: buildapprovdata?.FathersHusbandsName}),
    this.constructionForm.patchValue({correspondenceAddress: buildapprovdata?.CorrespondenceAddress}),
    this.constructionForm.patchValue({maxBuildingHeight: buildapprovdata?.MaxBuildingHeight}),
    this.constructionForm.patchValue({plotNumber: buildapprovdata?.PlotNumber}),
    this.constructionForm.patchValue({constructedArea: buildapprovdata?.ConstructedArea}),
    this.constructionForm.patchValue({proposedConstructedArea: buildapprovdata?.ProposedConstructedArea}),
    this.constructionForm.patchValue({natureOfConstruction: buildapprovdata?.NatureOfConstruction}),
    this.constructionForm.patchValue({architectName: buildapprovdata?.ArchitectName}),
    this.constructionForm.patchValue({PropertyCost: buildapprovdata?.PropertyCost}),
    this.constructionForm.patchValue({buildingApprovalFee: buildapprovdata?.BuildingApprovalFee}),
    this.constructionForm.patchValue({plotArea: buildapprovdata?.PlotArea}),
    this.constructionForm.patchValue({mobileNumber: buildapprovdata?.MobileNumber}),
    this.constructionForm.patchValue({aadharCardNumber: buildapprovdata?.AadharCardNumber}),
    this.constructionForm.patchValue({buildingapproval_status:buildapprovdata?.ApprovalStatus});
    this.selUploadedDocuments=buildapprovdata.attachments;
    // Load grievance details if needed
  }
  ngOnInit(): void {
    this.lstLevel2=this.panchayatservice.getLevel2s();
    if(this.assetdetail){      
      this.constructionForm.patchValue({ward_id: this.assetdetail?.ward_id}),
      this.constructionForm.patchValue({applicantName: this.assetdetail?.PropertyOwnerName}),
      this.constructionForm.patchValue({fathersHusbandsName: this.assetdetail?.PropertyOwnerFatherName}),
      this.constructionForm.patchValue({plotArea: this.assetdetail?.PlotArea}),
      this.constructionForm.patchValue({mobileNumber: this.assetdetail?.PropertyOwnerMobile}),
      this.constructionForm.patchValue({aadharCardNumber: this.assetdetail?.PropertyOwnerAadhar});

    }
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
     // Update filter state with the changed value
     this.filterState = filterData.filterState; 
     // Call the method in other component to handle the filter changes
     if(this.filterService.isInitialized()){
     //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.getBuildingApprovalData();
     }
    });                 
  }
  onFilesChange(files: File[]): void {
    //console.log('Selected files:', files);
  }
  getBuildingApprovalData(): void {
    this.shareService.setParam(true);
    this.buildingapproval = [];
    if(this.assetdetail){
      this.panchayatservice.getTaxData("BuildingApprovalDetails",this.assetdetail?.SurveyJsonID).subscribe((data: any) => {
        if(data.length>0){        
          //console.log(data)
          this.buildingapproval = JSON.parse(data).BuildingApproval;  
        }           
        this.shareService.setParam(false);
      });    
    }
    else{
      this.panchayatservice.getAssetData("BuildingApprovalDetails",this.filterState,false,1,100).subscribe((data: any) => {
        if(data.length>0){        
          this.buildingapproval = JSON.parse(data).BuildingApproval;        
        }           
        this.shareService.setParam(false);
      });  
    }  
  }
  onSubmit() {
    if (this.constructionForm.valid) {
      console.log(this.constructionForm.value);
      const formValues = this.constructionForm.value;
      const submissionData = {
        ...formValues
      };      
      console.log(submissionData); 
    // Handle file upload and save status
      this.panchayatservice.SaveTaxData("AddBuildingApprovalData",JSON.stringify(submissionData)).subscribe((data: any) => {
        if(data.includes("Successfully")){
          alert(data.GrievanceStatus);
          this.getBuildingApprovalData();          
        }
      });
    }
  }
  expandedIndex: number | null = null;
  expandedMap: { [key: number]: boolean } = {};
  toggleExpand(asset: any) {
    if(asset.buildingapproval_status=='Approved'){
      this.expandedMap[asset.BuildingApprovalid] = !this.expandedMap[asset.BuildingApprovalid];
    }
  }

  isExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  printDiv(divName: string) {
    let filename ='Tax Slip'+'.pdf';
    let PdfDiv = document.getElementById(divName);
    const content = PdfDiv?.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="http://smilingbrains.com/assets/css/style.css?v=2.0" rel="stylesheet" />');
    printWindow?.document.write(`<style>
    table {
      border-collapse:collapse;
    }
    
    table tr td {
      border: solid black 1px;
      padding: 5px 7px;
    }
    
    table tr th {
      border: none;
      padding-top: 5px;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }  
}

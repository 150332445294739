import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webmenu',
  templateUrl: './webmenu.component.html',
  styleUrls: ['./webmenu.component.css']
})
export class WebMenuComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  } 
  
}
<header class="section page-header">
    <!-- RD Navbar-->
    <div class="rd-navbar-wrap rd-navbar-corporate">
        <nav class="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-fullwidth" data-xl-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-device-layout="rd-navbar-static" data-md-stick-up-offset="130px" data-lg-stick-up-offset="100px" data-stick-up="true" data-sm-stick-up="true" data-md-stick-up="true" data-lg-stick-up="true" data-xl-stick-up="true">
            <!-- <div class="rd-navbar-collapse-toggle" data-rd-navbar-toggle=".rd-navbar-collapse"><span></span></div>-->
            <div class="rd-navbar-inner">
                <!-- RD Navbar Panel-->
                <div class="rd-navbar-panel">
                    <!-- RD Navbar Toggle-->
                    <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                    <!-- RD Navbar Brand<img class="logo-default" src="images/logo-default-208x46.png" alt="" width="208" height="46" /><img class="logo-inverse" src="images/logo-default-208x46.png" alt="" width="208" height="46" />-->
                    <div class="row resplogo">
                        <div class="col-12 d-md-block d-md-block">
                            <div class="rd-navbar-brand"><a class="brand-name" href="#/webhome"><img class="logo-default" src="assets/images/upgovlogo.png" alt="" /></a></div>
                        </div>  <!--ff0075ed-->
                    </div>

                </div>
                <div class="rd-navbar-aside-center">
                    <div class="rd-navbar-nav-wrap" id="cssmenu">
                        <!-- RD Navbar Nav-->
                        <ul class="rd-navbar-nav btn-group-justified-me" style="text-transform: uppercase !important;">
                            <li><a href="#/webhome"><i class="fa fa-home"></i>&nbsp;मुख्य पृष्ठ</a></li>
                            <li><a href="#/gallery"><i class="fa fa-photo"></i>&nbsp;गेलरी</a></li>
                            <li class="has-sub">
                                <span class="submenu-button"></span>
                                <a href="javascript:;" data-hover="megamenu-dropdown" data-close-others="true">
                                    <i class="fa fa-map"></i>&nbsp;जोन
                                </a>
                                <ul class="">
                                    <li>
                                        <a href="#/wardlist">वार्ड सूची </a>
                                    </li>
                                    <li>
                                        <a href="#/memberlist"> सभासद सूची </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#/departments">
                                    <i class="fa fa-bank"></i>&nbsp;विभाग
                                </a>                               
                            </li>
                            <li>
                                <a href="#/services">
                                    <i class="fa fa-book"></i>&nbsp;सेवाएं
                                </a>                                
                            </li>
                            <li><a href="#/contactus"><i class="fa fa-phone"></i>&nbsp;संपर्क सूत्र</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>

import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { WebHomeComponent } from 'src/app/modules/home/webhome/webhome.component';
import { HomeLayoutRoutingModule } from './home.routing';
import { WebSliderModule } from 'src/app/modules/home/webslider/webslider.module';
import { WebGalleryComponent } from 'src/app/modules/home/webgallery/webgallery.component';
import { WebWardListComponent } from 'src/app/modules/home/wardlist/webwardlist.component';
import { WebMemberListComponent } from 'src/app/modules/home/memberlist/webmemberlist.component';
import { WebContactusComponent } from 'src/app/modules/home/webcontactus/webcontactus.component';
import { FooterModule } from 'src/app/modules/admin/footer/footer.module';
import { WebHeaderModule } from './webheader/webheader.module';
import { WebFooterModule } from './webfooter/webfooter.module';
import { WebMenuModule } from './webmenu/webmenu.module';
import { HomeLayoutComponent } from './home-layout/home-layout.component';


@NgModule({
  imports: [
    HomeLayoutRoutingModule,
    FormsModule,
    //NgbModule,
    LightboxModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WebSliderModule,
    FooterModule,
    WebHeaderModule,
    WebFooterModule,
    WebMenuModule
  ],
  schemas:[NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  declarations: [       
    HomeLayoutComponent,
    WebHomeComponent,
    WebGalleryComponent,
    WebWardListComponent,
    WebMemberListComponent,
    WebContactusComponent,
  ]
})

export class HomeLayoutModule { }

<div class="card shadow-sm stats">
    <div class="card-body">
        <div class="stats-title">{{ title }}</div>
        <div class="stats-amount">
            <p-progressSpinner [style]="{ width: '30px', height: '30px' }" aria-label="Loading" *ngIf="!isdataloaded"></p-progressSpinner>
            <span class="stats-value" *ngIf="activevalue>-1 && isdataloaded" [countUp]="activevalue"></span><span class="stats-value" *ngIf="activevalue>-1 && isdataloaded">&nbsp;/&nbsp;</span><span class="stats-value" *ngIf="value>-1 && isdataloaded" [countUp]="value"></span>
            <i class="fa fa-clock-o fa-2x" *ngIf="value==-1 && isdataloaded" aria-hidden="true"></i>
            <span class="stats-change" *ngIf="isdataloaded && change>-1">+{{ change }}%</span>
            <div class="stats-link">                
                <a (click)="show()" *ngIf="loadmore" href="javascript:;">View Detail&nbsp;<i class="fa fa-arrow-alt-circle-right" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaxationListComponent } from './taxation-list/taxation-list.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { PariwarRegisterComponent } from './pariwar-register/pariwar-register.component';
import { BuildingApprovalComponent } from './building-approval/building-approval.component';
import { TradeLicenseComponent } from './trade-license/trade-license.component';
import { AssetLeaseComponent } from './asset-lease/asset-lease.component';
import { GrievanceComponent } from './grievance/add-grievance/grievance.component';
import { ReportHouseTaxComponent } from './report-house-tax/report-house-tax.component';
import { ReportWaterTaxComponent } from './report-water-tax/report-water-tax.component';

const routes: Routes = [
    {
        path: '', component: AdminLayoutComponent, children: [           
        { path: 'taxation', component: TaxationListComponent },
        { path: 'pariwar', component: PariwarRegisterComponent },
        { path: 'buildingapproval', component: BuildingApprovalComponent },
        { path: 'tradelicense', component: TradeLicenseComponent },
        { path: 'assetlease', component: AssetLeaseComponent },
        { path: 'grievance', component: GrievanceComponent },
        { path: 'reporthousetax', component: ReportHouseTaxComponent },
        { path: 'reportwatertax', component: ReportWaterTaxComponent },
        ]
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
<h2 class="innerh2">सेवाएं </h2><br />

<section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">


    <div class="container" style="margin-top: 20px;">
        <div class="row">

            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-edit icon" style="font-size: 100px;"> </i> <br>जन्म मृत्यु एवं विवाह का पंजीकरण </div>
            </div>
            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-compressed icon" style="font-size: 95px;"> </i><br> <br>जल एवं सीवरेज चार्गेज  </div>
            </div>
            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-calendar icon" style="font-size: 100px;"> </i> <br><br>इ-प्रोक्योरमेंट ( निविदायें )</div>
            </div>
            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-tint icon" style="font-size: 100px;"> </i><br> <br>नलकूप ऑटोमेशन  </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-th-list icon" style="font-size: 100px;"> </i> <br>शिकायतों का सुधार </div>
            </div>
            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-home icon" style="font-size: 100px;"> </i> <br>संपत्ति कर  </div>
            </div>
            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-bullhorn icon" style="font-size: 100px;"> </i> <br>विज्ञापन कर  </div>
            </div>
            <div class="col-md-3">
                <div class="well"><i class="glyphicon glyphicon-equalizer icon" style="font-size: 100px;"> </i> <br>मकान रजिस्टर  </div>
            </div>
        </div>
    </div>

</section>

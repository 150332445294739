<div class="row">
    <div class="col-md-12">
        <div class="portlet light bordered">
            <div class="portlet-title">
                <div class="caption font-dark">
                    <h4>Water Charge List</h4>
                </div>
            </div>
            <div class="portlet-body">
                <div class="row">
                    <div class="col-md-12">
                        <div id="no-more-tables">
                            <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small;font-weight:bold">
                                <thead class="cf">
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Transaction No.</th>
                                        <th>Current Charge</th>
                                        <th>Arrear</th>
                                        <th>Prev.Balance</th>
                                        <th>Interest</th>                                        
                                        <th>Discount</th>
                                        <th>Total Charge</th>
                                        <th>Tax Paid</th>
                                        <th>Balance</th>
                                        <th>Date</th>
                                        <th>Payment Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let assettaxh of WaterTax; let i = index">
                                        <tr (click)="toggleExpand(assettaxh.HouseTaxID)">
                                            <td>{{i + 1}}</td>
                                            <td>{{assettaxh.transactionNumber}}</td>
                                            <td>{{assettaxh.currentyeartax}}</td>
                                            <td>{{assettaxh.arrear}}</td>
                                            <td>{{assettaxh.PreviousBalance}}</td>
                                            <td>{{assettaxh.interest}}</td>
                                            <td>{{assettaxh.discount}}</td>
                                            <td>{{assettaxh.TotalTaxAmount}}</td>
                                            <td>{{assettaxh.TaxPaid}}</td>
                                            <td>{{assettaxh.BalanceTax}}</td>
                                            <td>{{assettaxh.taxdate}}</td>
                                            <td>{{assettaxh.TaxPaymentMode}}</td>
                                        </tr>
                                        <tr *ngIf="expandedMap[assettaxh.HouseTaxID]" class="fold open">
                                            <td colspan="7" class="m-srp-card">
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align:right">
                                                        <button class="btn btn-warning" (click)="printDiv('dvWatertaxprint')">Print</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" id="dvWatertaxprint">
                                                        <div class="row">
                                                            <div class="col-md-12" id="dvWatertaxprint">
                                                                <table style="font-size: small;font-weight:bold;width:600px;border:1px solid black;">
                                                                    <tr>
                                                                        <td colspan="4" style="text-align:center;">
                                                                            <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG" style="width:600px;">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="4" style="text-align:center;">
                                                                            <strong> वॉटर चार्ज रशीद </strong>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2"><qrcode
                                                                            qrdata="{{assettaxh.transactionNumber}} | {{assettaxh.taxdate}} | {{assettaxh.TaxPaid}}"
                                                                            [allowEmptyString]="true"
                                                                            [cssClass]="'center'"
                                                                            [colorDark]="'#000000ff'"
                                                                            [colorLight]="'#ffffffff'"
                                                                            [elementType]="'img'"
                                                                            [errorCorrectionLevel]="'M'"
                                                                            [scale]="1"
                                                                            [width]="80"
                                                                            ></qrcode></td>
                                                                        <td style="text-align:right;" colspan="2">
                                                                            <strong>Transaction Number :</strong>{{assettaxh.transactionNumber}}<br/>                                                                            
                                                                            <strong>Date:</strong>{{assettaxh.taxdate}}<br/>                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="width:25%">
                                                                            <b>Name of Owner</b>
                                                                        </td>
                                                                        <td style="text-align:left;width:25%">
                                                                            {{assettaxh.OwnerName}}
                                                                        </td>
                                                                        <td style="width:25%">
                                                                            <b>Father/Husband Name</b>
                                                                        </td>
                                                                        <td style="text-align:left;width:25%">
                                                                            {{assettaxh.OwnerFatherName}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Premisis Address:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">
                                                                            {{assettaxh.OwnerAddress}},{{assettaxh.OwnerMohalla}}
                                                                        </td>
                                                                        <td>
                                                                            <b>Ward:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">
                                                                            {{assettaxh.OwnerWard}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Previous Balance:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">
                                                                            {{assettaxh.PreviousBalance}}
                                                                        </td>
                                                                        <td>
                                                                            <b>Current Charge:</b>
                                                                        </td>
                                                                        <td  style="text-align:left;">{{assettaxh.currentyeartax}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Arrear:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">{{assettaxh.arrear}}</td>
                                                                        <td>
                                                                            <b>Interest:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">{{assettaxh.interest}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Discount:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">{{assettaxh.discount}}</td>
                                                                        <td>
                                                                            <b>Total Charge Amount:</b>
                                                                        </td>
                                                                        <td style="text-align:left;">{{assettaxh.TotalTaxAmount}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Amount received :</b>
                                                                        </td>
                                                                        <td style="text-align:left;">
                                                                            {{assettaxh.TaxPaid}}
                                                                        </td>
                                                                        <td>
                                                                            <b>Balance Amount :</b>
                                                                        </td>
                                                                        <td style="text-align:left;">
                                                                            {{assettaxh.BalanceTax}}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Payment Mode :</b>
                                                                        </td>
                                                                        <td style="text-align:left;">
                                                                            {{assettaxh.TaxPaymentMode}}
                                                                        </td>
                                                                        <td>Reference Number:</td>
                                                                        <td style="text-align:left;">{{assettaxh.TaxPaymentMode=='cash'?'NA':assettaxh.referenceNumber}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="4" align="right">
                                                                            <br /><br /><br />
                                                                            <b>Executive Officer/ Secretary</b>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form [formGroup]="WaterTaxForm">
                            <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" cellpadding="3" cellspacing="3" style="font-size: small;font-weight:bold;border-collapse: collapse;">
                                <tr>
                                    <td colspan="4" style="text-align:center;">
                                        <h4>Pending Charge</h4>
                                        <p-progressSpinner *ngIf="ispendinglistloaded" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <table width="100%">
                                            <tr>
                                                <td>S.No.</td>
                                                <td>Financial Year</td>
                                                <td>Charge Amount</td>
                                                <td>Arrear</td>
                                                <td>Interest</td>
                                                <td>Total Pending Amount</td>
                                            </tr>
                                            <tr *ngFor="let pendtaxh of pendingWaterTax; let i = index">
                                                <td>{{i + 1}}</td>
                                                <td>{{pendtaxh.FinancialYear}}</td>
                                                <td>{{pendtaxh.currentyeartax}}</td>
                                                <td>{{pendtaxh.arrear}}</td>
                                                <td>{{pendtaxh.interest}}</td>
                                                <td>{{pendtaxh.taxamount}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr style="display: none;">
                                    <td><b>Transaction Number :</b></td>
                                    <td><input formControlName="transactionNumber" class='form-control' type="text"></td>                               
                                </tr>
                                <tr>
                                    <td><b>Previous Balance:</b></td>
                                    <td><input formControlName="PreviousBalance" class='form-control' type="text">
                                        <div class="font-red" *ngIf="submitted && WaterTaxForm.controls['PreviousBalance'].errors">
                                            <div *ngIf="WaterTaxForm.controls['PreviousBalance'].errors['required']">Enter Previous Balance.</div>
                                        </div>
                                    </td>
                                    <td><b>Current Charge Amount:</b></td>
                                    <td><input formControlName="currentyeartax" class='form-control' type="text" readonly></td>                               
                                </tr>
                                <tr>
                                    <td><b>Arrear:</b></td>
                                    <td><input formControlName="arrear" class='form-control' type="text" readonly>
                                        <div class="font-red" *ngIf="submitted && WaterTaxForm.controls['arrear'].errors">
                                            <div *ngIf="WaterTaxForm.controls['arrear'].errors['required']">Enter Arrear.</div>
                                        </div>
                                    </td>
                                    <td><b>Interest:</b></td>
                                    <td><input formControlName="interest" class='form-control' type="text" readonly>
                                        <div class="font-red" *ngIf="submitted && WaterTaxForm.controls['interest'].errors">
                                            <div *ngIf="WaterTaxForm.controls['interest'].errors['required']">Enter Interest.</div>
                                        </div>
                                    </td>                                    
                                </tr>                                
                                <tr>                                    
                                    <td><b>Discount:</b></td>
                                    <td><input formControlName="discount" class='form-control' type="text">
                                        <div class="font-red" *ngIf="submitted && WaterTaxForm.controls['discount'].errors">
                                            <div *ngIf="WaterTaxForm.controls['discount'].errors['required']">Enter Discount.</div>
                                        </div>
                                    </td>
                                    <td><b>Total Charge Amount:</b></td>
                                    <td><input [value]="calculateFinalPaymentWater()" class='form-control' readonly></td>                                    
                                </tr> 
                                <tr>
                                    <td><b>Paid Amount:</b></td>
                                    <td><input formControlName="paidAmount" class='form-control' type="text">
                                        <div class="font-red" *ngIf="submitted && WaterTaxForm.controls['paidAmount'].errors">
                                            <div *ngIf="WaterTaxForm.controls['paidAmount'].errors['required']">Enter Paid Amount.</div>
                                        </div>
                                    </td>                                
                                    <td><b>Mode of Payment:</b></td>
                                    <td>
                                        <select formControlName="paymentMethod" class='form-control' (change)="onPaymentMethodChange($event)">
                                            <option value="cash">Cash</option>
                                            <option value="cheque">Cheque</option>
                                            <option value="transfer">Transfer</option>
                                            <option value="online">Online</option>
                                        </select>
                                        <div class="font-red"
                                                *ngIf="submitted && WaterTaxForm.controls['paymentMethod']?.value==''">
                                            Please Select Mode of Payment.
                                        </div>
                                    </td>
                                </tr>                                
                                <tr *ngIf="selectedPaymentMethod === 'cheque' || selectedPaymentMethod === 'transfer'">
                                    <td><b>{{selectedPaymentMethod === 'cheque'?'Cheque ':'UTR'}} Number:</b></td>
                                    <td><input formControlName="referenceNumber" class='form-control' type="text"></td>
                                    <td><b>{{selectedPaymentMethod === 'transfer'?'Transfer':'Cheque'}} Date:</b></td>
                                    <td><input formControlName="transactionDate" class='form-control' type="date"></td>
                                </tr>                               
                                <tr *ngIf="selectedPaymentMethod === 'cheque'">
                                    <td><b>Bank Name:</b></td>
                                    <td><input formControlName="chequeBankName" class='form-control' type="text"></td>                                    
                                </tr>                                
                                <tr>
                                    <td colspan="4">
                                        <div class="m-srp-card__action">
                                            <div class="m-srp-card__action__item">
                                                <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="onSubmit()">
                                                    Save
                                                </a>&nbsp;
                                                <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="closedialog()">
                                                    Close
                                                </a> 
                                            </div>
                                        </div>                                          
                                    </td>
                                </tr>
                            </table>                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



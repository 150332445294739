<section class="header-othersite">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8">
                <div class="header-othersite-lnk">
                    <ul>
                        <li> <a target="_blank" href="http://plus.google.com/"><i class="fa fa-google-plus"></i></a>  </li>
                        <li> <a target="_blank" href="http://www.facebook.com/"><i class="fa fa-facebook"></i></a>  </li>
                        <li> <a target="_blank" href="https://twitter.com/"><i class="fa fa-twitter"></i></a>  </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 loginLink">
                <ul>
                    <li class="fontdecrease"><a href="#" title="Text Size: Decrease -6%"><img src="assets/images/decrease_6.gif" alt="Decrease Text"></a></li>
                    <li class="fontreset"><a href="#" title="Text Size: Normal =100%"><img src="assets/images/normalfont.gif" alt="Normal Text"></a></li>
                    <li class="fontincrease"><a href="#" title="Text Size: Increase 6%"><img src="assets/images/increase_6.gif" alt="Increase Text"></a></li>
                    <li><a href="#/login" class="btn btn-sm btn-primary pull-right btn-login">LOGIN</a></li>
                    <li><a href="#" class="btn btn-sm btn-primary pull-right btn-login">Email</a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section style="padding-right: 20px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-2 pl-0 pr-0 text-right d-none d-md-block d-md-block">
                <div class="nationalLogo">
                    <a href="#" target="_blank"><img src="assets/images/upgovlogo.png?v=1.5" class="img-fluid"></a>
                </div>
            </div>  <!--ff0075ed-->
            <div class="col-lg-6" style="padding: 3px;">
                <h3 style="font-weight: 500; text-shadow:2px 2px 8px purple;"><strong>नगर पंचायत - मानिकपुर</strong> </h3>
                <h4 style="font-weight: 500; text-shadow:2px 2px 8px purple;"><strong>जनपद - प्रतापगढ़ </strong> </h4>
            </div>
            <div class="col-lg-1 pl-0 pr-0 text-right d-none d-md-block d-md-block">
                <div class="app-sectioncitizen"><a href="http://smilingbrains.com/apk/CitizenConnect.apk" class="mygov_applink" target="_blank" title=" SCAN TO DOWNLOAD Citizen Mobile App">                    
                    <small></small>
                    Citizen App</a>                        
                </div>
            </div>
            <div class="col-lg-1 pl-0 pr-0 text-right d-none d-md-block d-md-block">
            </div>
            <div class="col-lg-1 pl-0 pr-0 text-right d-none d-md-block d-md-block">
                <div class="app-sectionofficer"><a href="http://smilingbrains.com/apk/SmileConnect.apk" class="mygov_applink" target="_blank" title=" SCAN TO DOWNLOAD Officer Mobile App">                    
                    <small></small>
                    Officer App</a>                        
                </div>
            </div>
            <div class="col-lg-1 pl-0 pr-0 text-right d-none d-md-block d-md-block">
            </div>
        </div>
    </div>
</section>

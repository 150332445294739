import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webcontactus',
  templateUrl: './webcontactus.component.html',
  styleUrls: ['./webcontactus.component.css']
})
export class WebContactusComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  } 
}

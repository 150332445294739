import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WebSliderComponent } from './webslider.component';

@NgModule({
    imports: [ RouterModule, CommonModule,FormsModule
     ],
    declarations: [ WebSliderComponent ],
    exports: [ WebSliderComponent ]
})

export class WebSliderModule {}

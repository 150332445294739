<div class="row">
    <div *ngIf="assetdetail" class="col-md-12"><button class="btn btn-primary" type="submit" (click)="printDiv()">Generate Pdf</button></div>
    <div class="col-md-12" *ngIf="assetdetail && profiletype=='Complete'">
        <div class="card flex justify-content-center">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <p-selectButton 
                        [options]="lstprofiletype" 
                        [(ngModel)]="selProfiletype" 
                        [multiple]="false" 
                        optionLabel="name" 
                        optionValue="value" />                   
                </div>                                
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <form [formGroup]="profileForm">
            <div class="table-responsive" style="width:900px !important;" #PdfDiv id="PdfDiv">            
                <table style="font-size: 16px;border-width:1px;width:890px !important;border-collapse:collapse;" cellpadding="2">
                    <tr *ngIf="assetdetail">
                        <td colspan="4" style="text-align:center;border: solid black 1px;padding: 5px 7px;">
                            <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center;border: solid black 1px;">
                            <qrcode
                              [qrdata]="assetdetail?.PropertyID"
                              [allowEmptyString]="true"
                              [cssClass]="'center'"
                              [colorDark]="'#000000ff'"
                              [colorLight]="'#ffffffff'"
                              [elementType]="'img'"
                              [errorCorrectionLevel]="'M'"
                              [scale]="1"
                              [width]="80"
                            ></qrcode>
                        </td>
                        <td colspan="2" style="text-align:right;padding-right: 20px;border: solid black 1px;">
                            <strong> यूनिक ID : {{assetdetail?.PropertyID}}</strong><br/>
                            <strong>दिनाँक : {{currentDate}}</strong>
                        </td>
                    </tr>
                    <tr *ngIf="assetdetail">
                        <td colspan="4" style="text-align:center;">
                            <strong>परिवार रजिस्टर </strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align:left;" *ngIf="profiletype=='PariwarNakal'">
                            प्रमाणित किया जाता है कि <strong>{{assetdetail?.FamilyOwnerGender == 'पुरुष'?'श्री':'श्रीमती'}}&nbsp;{{assetdetail?.FamilyOwnerName}}</strong>{{assetdetail?.FamilyOwnerGender == 'पुरुष'? 'पुत्र':'पुत्री'}}&nbsp;<strong>{{assetdetail?.FamilyOwnerFatherName}}</strong> निवासी  <strong>{{assetdetail?.AssetAddress}},{{assetdetail?.Mohalla}}</strong> वार्ड -<strong>{{assetdetail?.Ward}}</strong>, जनपद - प्रतापगढ़ (उ० प्र०) के निवासी है |
                        </td>
                    </tr>  
                    <tr *ngIf="assetdetail && profiletype=='Complete'">
                        <td colspan="4" style="text-align:center;">
                            <strong>सम्पत्ति का विवरण </strong>
                        </td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete'">
                        <td style="font-weight:bold;">पंचायत भवन सँख्या</td>
                        <td>{{assetdetail?.PanchayatHouseNumber}}<input class='form-control' formControlName="PanchayatHouseNumber" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">वार्षिक मूल्य</td>
                        <td>{{assetdetail?.AnnualValue}}<input class='form-control' formControlName="AnnualValue" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">वार्षिक मूल्य का वर्ष</td>
                        <td>{{assetdetail?.AnnualValueYear}}<input class='form-control' formControlName="AnnualValueYear" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">भवन के निर्माण का वर्ष</td>
                        <td>{{assetdetail?.ConstructionYear}}<input class='form-control' formControlName="ConstructionYear" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete' || profiletype=='AddWaterCharge'">                        
                        <td style="font-weight:bold;">जल कनेक्शन संख्या</td>
                        <td colspan="3">{{assetdetail?.WaterConnectionNumber}}<input class='form-control' formControlName="WaterConnectionNumber" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">सम्पत्ति के मालिक का नाम</td>
                        <td>{{assetdetail?.PropertyOwnerName}}<input class='form-control' formControlName="PropertyOwnerName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">सम्पत्ति के मालिक के पिता / पति का नाम</td>
                        <td>{{assetdetail?.PropertyOwnerFatherName}}<input class='form-control' formControlName="PropertyOwnerFatherName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">सम्पत्ति के मालिक की लिंग</td>
                        <td>{{assetdetail?.PropertyOwnerGender}}
                        <select class="form-control" formControlName="PropertyOwnerGender" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                            <option value="पुरुष">पुरुष</option>
                            <option value="स्त्री">स्त्री</option>
                        </select>
                        </td>
                        <td style="font-weight:bold;">सम्पत्ति के मालिक की आयु</td>
                        <td>{{assetdetail?.PropertyOwnerAge}}<input class='form-control' formControlName="PropertyOwnerAge" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr> 
                    <tr *ngIf="profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">सम्पत्ति के मालिक का फ़ोन नंबर</td>
                        <td>{{assetdetail?.PropertyOwnerMobile}}<input class='form-control' formControlName="PropertyOwnerMobile" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">सम्पत्ति के मालिक का आधार कार्ड नम्बर</td>
                        <td>{{assetdetail?.PropertyOwnerAadhar}}<input class='form-control' formControlName="PropertyOwnerAadhar" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>      
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                      <td style="font-weight:bold;">मालिक का नाम</td>
                      <td>{{assetdetail?.FamilyOwnerName}}<input class='form-control' formControlName="FamilyOwnerName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                      <td style="font-weight:bold;">पिता / पति का नाम</td>
                      <td>{{assetdetail?.FamilyOwnerFatherName}}<input class='form-control' formControlName="FamilyOwnerFatherName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                      <td style="font-weight:bold;">मालिक की लिंग</td>
                      <td>{{assetdetail?.FamilyOwnerGender}}
                        <select class="form-control" formControlName="FamilyOwnerGender" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                            <option value="पुरुष">पुरुष</option>
                            <option value="स्त्री">स्त्री</option>
                        </select>
                      </td>
                      <td style="font-weight:bold;">मालिक की आयु</td>
                      <td>{{assetdetail?.FamilyOwnerAge}}<input class='form-control' formControlName="FamilyOwnerAge" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>                
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                      <td style="font-weight:bold;">पता</td>
                      <td>{{assetdetail?.AssetAddress}}<input class='form-control' formControlName="AssetAddress" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                      <td style="font-weight:bold;">मोहल्ला</td>
                      <td>{{assetdetail?.Mohalla}}<input class='form-control' formControlName="Mohalla" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                      <td style="font-weight:bold;"> लैंडमार्क </td>
                      <td>{{assetdetail?.Landmark}}<input class='form-control' formControlName="Landmark" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                      <td style="font-weight:bold;">वार्ड</td>
                      <td>{{assetdetail?.Ward}}
                        <select class='form-control' formControlName="ward_id">                            
                            <option value="{{row.Level2_id}}" *ngFor="let row of lstLevel2">{{row.Level2_Name}}</option>
                        </select>

                      </td>
                    </tr>  
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                      <td style="font-weight:bold;">फ़ोन नंबर</td>
                      <td>{{assetdetail?.FamilyOwnerMobile}}<input class='form-control' formControlName="FamilyOwnerMobile" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                      <td style="font-weight:bold;">वर्ग / श्रेणी</td>
                      <td>{{assetdetail?.Category}}
                        <select class="form-control" formControlName="Category" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                            <option value="General">General</option>
                            <option value="OBC">OBC</option>
                            <option value="SC">SC</option>
                            <option value="ST">ST</option>
                        </select>
                      </td>
                    </tr>  
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                      <td style="font-weight:bold;">धर्म</td>
                      <td>{{assetdetail?.Religion}}
                        <select class="form-control" formControlName="Religion" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                            <option value="हिंदु">हिंदु</option>
                            <option value="मुस्लिम">मुस्लिम</option>
                            <option value="सिख़">सिख़</option>
                            <option value="इसाई">इसाई</option>
                        </select>
                      </td>
                      <td style="font-weight:bold;">जाति</td>
                      <td>{{assetdetail?.Caste}}<input class='form-control' formControlName="Caste" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;"> क्षेत्रफ़ल</td>
                        <td>{{assetdetail?.PlotArea}}<input class='form-control' formControlName="PlotArea" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">निर्मित क्षेत्रफ़ल</td>
                        <td>{{assetdetail?.ConstructedArea}}<input class='form-control' formControlName="ConstructedArea" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete'">
                        <td style="font-weight:bold;">अस्थायी निर्मित क्षेत्रफ़ल</td>
                        <td>{{assetdetail?.temporaryConstructArea}}<input class='form-control' formControlName="temporaryConstructArea" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">मंजिलों की संख्या</td>
                        <td>{{assetdetail?.NoOfFloor}}<input class='form-control' formControlName="NoOfFloor" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Complete'">
                        <td style="font-weight:bold;">कमरों की संख्या</td>
                        <td>{{assetdetail?.NoOfRoom}}<input class='form-control' formControlName="NoOfRoom" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">दुकानों की संख्या</td>
                        <td>{{assetdetail?.NoOfShop}}<input class='form-control' formControlName="NoOfShop" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                        <td style="font-weight:bold;">स्वामित्व का प्रकार</td>
                        <td>{{assetdetail?.OwnershipType}}
                            <select class="form-control" formControlName="OwnershipType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="Rented">Rented</option>
                                <option value="SelfOwned">SelfOwned</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">पंजीकरण का प्रकार</td>
                        <td>{{assetdetail?.PlotRegisterType}}
                            <select class="form-control" formControlName="PlotRegisterType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="बैनामा">बैनामा</option>
                                <option value="वसीयत">वसीयत</option>
                                <option value="पैतृक">पैतृक</option>
                                <option value="इकरारनामा">इकरारनामा</option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">सम्पत्ति श्रेणी</td>
                        <td>{{assetdetail?.PlotType}}
                            <select class="form-control" formControlName="PlotType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="आवासीय">आवासीय</option>
                                <option value="व्यवसायिक">व्यवसायिक</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">सदन की प्रकृति</td>
                        <td>{{assetdetail?.ConstructionType}}
                            <select class="form-control" formControlName="ConstructionType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="पक्का">पक्का</option>
                                <option value="कच्चा">कच्चा</option>
                            </select>
                        </td>
                    </tr>                                                     
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">रोड का प्रकार</td>
                        <td>{{assetdetail?.RoadType}}
                            <select class="form-control" formControlName="RoadType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="आर सी सी">आर सी सी</option>
                                <option value="डाम्बर">डाम्बर</option>
                                <option value="इंटरलॉकिंग">इंटरलॉकिंग</option>
                                <option value="खडंजा">खडंजा</option>
                                <option value="कच्चा">कच्चा</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">रोड की चौड़ाई</td>
                        <td>{{assetdetail?.RoadLength}}
                            <select class="form-control" formControlName="RoadLength" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="१२ मीटर से कम">१२ मीटर से कम</option>
                                <option value="१२ - २४ मीटर">१२ - २४ मीटर</option>
                                <option value="२४ मीटर अधिक">२४ मीटर अधिक</option>
                            </select>
                        </td>
                    </tr>       
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">रोड सफाई</td>
                        <td>{{assetdetail?.RoadCleaniness}}
                            <select class="form-control" formControlName="RoadCleaniness" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हाँ">हाँ</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">समरसेबल की स्थिति</td>
                        <td>{{assetdetail?.SummersablePumpStatus}}
                            <select class="form-control" formControlName="SummersablePumpStatus" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हाँ">हाँ</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>                    
                    </tr>         
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">पानी की आपूर्ति</td>
                        <td>{{assetdetail?.WaterSupplyType}}
                            <select class="form-control" formControlName="WaterSupplyType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हैंड पम्प">हैंड पम्प</option>
                                <option value="नगर पंचायत / पालिका">नगर पंचायत / पालिका</option>
                                <option value="खुद का">खुद का</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">बिजली का मीटर</td>
                        <td>{{assetdetail?.IsElectricitySupply}}
                            <select class="form-control" formControlName="IsElectricitySupply" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हाँ">हाँ</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">सीवेज का प्रकार</td>
                        <td>
                            {{assetdetail?.SewageType}}
                            <select class="form-control" formControlName="SewageType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="नाला">नाला</option>
                                <option value="सेफ्टी टैंक">सेफ्टी टैंक</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">सीवेज सफाई</td>
                        <td>{{assetdetail?.SewageCleaniness}}
                            <select class="form-control" formControlName="SewageCleaniness" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हाँ">हाँ</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                    </tr>                
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                        <td style="font-weight:bold;">गैस कनेक्शन</td>
                        <td>{{assetdetail?.IsGasConnection}}
                            <select class="form-control" formControlName="IsGasConnection" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हाँ">हाँ</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">मालिक इस घर में रहते है</td>
                        <td>{{assetdetail?.IsOwnerLiveHere}}
                            <select class="form-control" formControlName="IsOwnerLiveHere" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="हाँ">हाँ</option>
                                <option value="नहीं">नहीं</option>
                            </select>
                        </td>
                    </tr>                
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete' || profiletype=='AddWaterCharge'">
                        <td style="font-weight:bold;">शौचालय की संख्या</td>
                        <td>{{assetdetail?.NoOfToilet}}<input class='form-control' formControlName="NoOfToilet" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">शौचालय किस संस्था द्वारा</td>
                        <td>{{assetdetail?.ToiletConstructAgency}}
                            <select class="form-control" formControlName="FamilyOwnerGender" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="निजी">निजी</option>
                                <option value="पंचायत">पंचायत</option>
                            </select>
                        </td>
                    </tr>                
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                        <td style="font-weight:bold;">परिवार का प्रकार</td>
                        <td>{{assetdetail?.FamilyType}}
                            <select class="form-control" formControlName="FamilyType" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                <option value="APL">APL</option>
                                <option value="BPL">BPL</option>
                                <option value="AAY">AAY</option>
                            </select>
                        </td>
                        <td style="font-weight:bold;">परिवार का प्रकार नंबर</td>
                        <td>{{assetdetail?.FamilyTypeCardNo}}<input class='form-control' formControlName="FamilyTypeCardNo" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                        <td style="font-weight:bold;">आधार कार्ड नम्बर</td>
                        <td colspan="3">{{assetdetail?.AadharCardNo}}<input class='form-control' formControlName="AadharCardNo" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                        <td style="font-weight:bold;">पैन कार्ड नम्बर</td>
                        <td>{{assetdetail?.PanCardNo}}<input class='form-control' formControlName="PanCardNo" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">वोटर कार्ड नम्बर</td>
                        <td>{{assetdetail?.VoterCardo}}<input class='form-control' formControlName="VoterCardo" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>
                    <tr *ngIf="profiletype=='Applicant' || profiletype=='Complete'">
                        <td style="font-weight:bold;">पात्र गृहस्थी राशन कार्ड नम्बर</td>
                        <td>{{assetdetail?.RashanCardNo}}<input class='form-control' formControlName="RashanCardNo" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                        <td style="font-weight:bold;">पात्र गृहस्थी के मुखिया का नाम</td>
                        <td>{{assetdetail?.RashanCardNoOwnerName}}<input class='form-control' formControlName="RashanCardNoOwnerName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                    </tr>        
                    <tr>
                        <td colspan="4">
                            <div class="m-srp-card__action">
                                <div class="m-srp-card__action__item">
                                    <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="SaveApplicantData()">
                                        Save
                                    </a>                        
                                </div>
                            </div>  
                        </td>
                    </tr>                  
                    <tr *ngIf="profiletype!='AddWaterCharge'">
                        <td colspan="4" style="text-align:center;"><strong>सदस्य की जानकारी</strong></td>
                    </tr>
                    <tr *ngIf="profiletype!='AddWaterCharge'">
                        <td colspan="4">
                            <table width="100%">
                                <thead>                                
                                    <tr>
                                        <td style="font-weight:bold;">सदस्य का नाम </td>
                                        <td style="font-weight:bold;">पिता / पति / माता का नाम </td>
                                        <td style="font-weight:bold;">मालिक से सम्बन्ध</td>
                                        <td style="font-weight:bold;">उम्र</td>
                                        <td style="font-weight:bold;">लिंग</td>
                                        <td style="font-weight:bold;">फ़ोन नंबर</td>
                                        <td style="font-weight:bold;">आधार कार्ड नम्बर </td>
                                        <td style="font-weight:bold;">शैक्षिक योग्यता</td>
                                        <td style="font-weight:bold;">वैवाहिक स्तिथि</td>
                                        <td style="font-weight:bold;">वार्षिक आय</td>
                                        <td *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                            <a (click)="addMember()"><i class="fa fa-plus"></i></a>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody formArrayName="memberList">
                                    <tr *ngFor="let c of memberList.controls; let i = index" [formGroupName]="i">
                                        <td>{{c.get('MemberName')?.value}}<input class='form-control' formControlName="MemberName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                                        <td>{{c.get('MemberGaurdianName')?.value}}<input class='form-control' formControlName="MemberGaurdianName" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                                        <td>{{c.get('MemberRelation')?.value}}
                                            <select  formControlName="MemberRelation"  class="form-control" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                                <option value=""></option>
                                                <option>दादी</option>
                                                <option>बाबा</option>
                                                <option>पोता</option>
                                                <option>पोती</option>
                                                <option>नानी</option>
                                                <option>नाना</option>
                                                <option>नाती</option>
                                                <option>नातिन</option>
                                                <option>पापा</option>
                                                <option>मम्मी</option>
                                                <option>पति</option>
                                                <option>पत्नी</option>
                                                <option>बेटा</option>
                                                <option>बेटी</option>
                                                <option>भाई</option>
                                                <option>बहन</option>
                                                <option>चचेरा भाई</option>
                                                <option>चचेरी बहन</option>
                                                <option>बुआ</option>
                                                <option>फूफा</option>
                                                <option>चाची</option>
                                                <option>चाचा</option>
                                                <option>मामी</option>
                                                <option>मामा</option>
                                                <option>मौसी</option>
                                                <option>मौसा</option>
                                                <option>सास</option>
                                                <option>ससुर</option>
                                                <option>ज्येठानी</option>
                                                <option>ज्येठ</option>
                                                <option>देवरानी</option>
                                                <option>देवर</option>
                                                <option>बहू</option>
                                                <option>दामाद</option>
                                                <option>ननद</option>
                                                <option>भाभी</option>
                                                <option>भांजी</option>
                                                <option>भांजा</option>
                                                <option>भतीजी</option>
                                                <option>भतीजा</option>
                                            </select></td>
                                        <td>{{c.get('MemberAge')?.value}}<input class='form-control' formControlName="MemberAge" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                                        <td>{{c.get('MemberGender')?.value}}
                                            <select class="form-control" formControlName="MemberGender" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                                <option value="पुरुष">पुरुष</option>
                                                <option value="स्त्री">स्त्री</option>
                                            </select></td>
                                        <td>{{c.get('MemberMobile')?.value}}<input class='form-control' formControlName="MemberMobile" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                                        <td>{{c.get('MemberAadharCardNo')?.value}}<input class='form-control' formControlName="MemberAadharCardNo" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                                        <td>{{c.get('MemberEducationStatus')?.value}}
                                            <select class="form-control" formControlName="MemberEducationStatus" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                                <option value="8th">8th</option>
                                                <option value="10th">10th</option>
                                                <option value="12th ">12th</option>
                                                <option value="Graduate">Graduate</option>
                                                <option value="PG">PG</option>
                                                <option value="Diploma">Diploma</option>
                                            </select>
                                            </td>
                                        <td>{{c.get('MemberMarritalStatus')?.value}}
                                            <select class="form-control" formControlName="MemberMarritalStatus" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                                <option value="विवाहित">विवाहित</option>
                                                <option value="अविवाहित">अविवाहित</option>
                                                <option value="विधवा">विधवा</option>
                                                <option value="विधुर">विधुर</option>
                                            </select>
                                            </td>
                                        <td>{{c.get('MemberIncome')?.value}}<input class='form-control' formControlName="MemberIncome" type="text" *ngIf="selProfiletype=='Update' || selProfiletype=='Add'"></td>
                                        <td *ngIf="selProfiletype=='Update' || selProfiletype=='Add'">
                                            <a (click)="saveMember(i)"><i class="fa fa-save" style="color:green"></i></a>
                                            <a (click)="removeMember(i)"><i class="fa fa-remove" style="color:red"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>                
                </table>
            </div> 
        </form>       
    </div>
</div>

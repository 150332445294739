import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webgallery',
  templateUrl: './webgallery.component.html',
  styleUrls: ['./webgallery.component.css']
})
export class WebGalleryComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  } 
}

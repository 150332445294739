import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnChanges {
    @Input() totalItems: number = 0;
    @Input() currentPage: number = 1;
    @Output() currentPageChange = new EventEmitter<number>();
    @Output() pageChange = new EventEmitter<number>();
    @Input() itemperpage: number = 100;
    totalPages: number = 0;
    pages: (number | string)[] = [];
  
    ngOnChanges() {
      this.totalPages = Math.ceil(this.totalItems / this.itemperpage);
      this.generatePages();
    }
  
    setPage(page: any) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.currentPageChange.emit(this.currentPage);
        this.pageChange.emit(this.currentPage);
        this.generatePages();
      }
    }
  
    nextPage() {
      this.setPage(this.currentPage + 1);
    }
  
    previousPage() {
      this.setPage(this.currentPage - 1);
    }
    
    private generatePages() {
      this.pages = [];
      if (this.totalPages <= 20) {
        for (let i = 1; i <= this.totalPages; i++) {
          this.pages.push(i);
        }
      } else {
        this.pages.push(1);
        if (this.currentPage > 4) {
          this.pages.push('...');
        }
        let startPage = Math.max(2, this.currentPage - 2);
        let endPage = Math.min(this.totalPages - 1, this.currentPage + 2);
        for (let i = startPage; i <= endPage; i++) {
          this.pages.push(i);
        }
        if (this.currentPage < this.totalPages - 3) {
          this.pages.push('...');
        }
        this.pages.push(this.totalPages);
      }
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CountUpModule } from 'ngx-countup';
import { StatsComponent } from './stats.component';

@NgModule({
  declarations: [
    StatsComponent
],
  exports:[StatsComponent],
  imports: [   
    CountUpModule,   
    DialogModule,
    CommonModule,
    ProgressSpinnerModule,    
  ]
})
export class StatsModule { }

<div class="row">
    <div class="col-md-12">
        <div class="heading">
            <h4>Grievance<small></small></h4>
        </div>
    </div>
    <div class="col-md-12">
        <div class="card flex justify-content-center">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <p-selectButton 
                        [options]="lstprofiletype" 
                        [(ngModel)]="selviewtype" 
                        [multiple]="false" 
                        optionLabel="name" 
                        optionValue="value"
                        (onChange)="onchangetype()" />  
                </div>  
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div id="no-more-tables" class="table-scrollable table-scrollable-borderless table-responsive">
            <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small; font-weight: bold;">
                <thead class="cf">
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Mobile</th>
                        <th>Department</th>
                        <th>Category</th>
                        <th>Date</th>
                        <th>Mark To</th>
                        <th>Marking Date</th>
                        <th>Details</th>
                        <th>Source</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let asset of GrievanceList">
                        <tr >
                            <td><a (click)="selectGrievance(asset)">Select</a></td>
                            <td data-title="asset.applicant_name" (click)="toggleExpand(asset.grievanceID)">{{asset.applicant_name}}</td>
                            <td data-title="asset.applicant_address" (click)="toggleExpand(asset.grievanceID)">{{asset.applicant_address}}</td>
                            <td data-title="asset.applicant_mobile" (click)="toggleExpand(asset.grievanceID)">{{asset.applicant_mobile}}</td>
                            <td data-title="asset.department" (click)="toggleExpand(asset.grievanceID)">{{asset.department}}</td>
                            <td data-title="asset.category" (click)="toggleExpand(asset.grievanceID)">{{asset.category}}</td>
                            <td data-title="asset.grievance_date" (click)="toggleExpand(asset.grievanceID)">{{asset.grievancedate}}</td>
                            <td data-title="asset.grievance_date" (click)="toggleExpand(asset.grievanceID)">{{asset.markedToName}}</td>
                            <td data-title="asset.grievance_date" (click)="toggleExpand(asset.grievanceID)">{{asset.markedOn}}</td>
                            <td data-title="asset.grievance_detail" (click)="toggleExpand(asset.grievanceID)">{{asset.grievance_detail}}</td>
                            <td data-title="asset.grievance_source" (click)="toggleExpand(asset.grievanceID)">{{asset.grievance_source}}</td>
                            <td data-title="asset.grievance_status" (click)="toggleExpand(asset.grievanceID)">{{asset.grievance_status}}</td>
                        </tr>
                        <tr *ngIf="expandedMap[asset.grievanceID]" class="fold open">
                            <td colspan="7" class="m-srp-card">
                                <div class="row">
                                    <div class="col-md-12" style="text-align:right">
                                        <button class="btn btn-warning" (click)="printDiv('dvhousetaxprint')">Print</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" id="dvhousetaxprint">
                                        <div class="row">
                                            <div class="col-md-12" id="dvhousetaxprint">
                                                <table style="font-size: small;font-weight:bold;width:600px;border:1px solid black;">
                                                    <tr>
                                                        <td colspan="4" style="text-align:center;">
                                                            <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG" style="width:600px;">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" style="text-align:center;">
                                                            <strong>शिकायत रशीद </strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"><qrcode
                                                            qrdata="{{asset.applicant_name}} | {{asset.grievancedate}}"
                                                            [allowEmptyString]="true"
                                                            [cssClass]="'center'"
                                                            [colorDark]="'#000000ff'"
                                                            [colorLight]="'#ffffffff'"
                                                            [elementType]="'img'"
                                                            [errorCorrectionLevel]="'M'"
                                                            [scale]="1"
                                                            [width]="80"
                                                            ></qrcode></td>
                                                        <td style="text-align:right;" colspan="2">
                                                            <strong>Transaction Number :</strong>GR2024-{{asset.grievanceID}}<br/>                                                                            
                                                            <strong>Date:</strong>{{asset.grievancedate}}<br/>                                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:25%">
                                                            <b>Name of Applicant</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{asset.applicant_name}}
                                                        </td>
                                                        <td style="width:25%">
                                                            <b>Mobile Number</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{asset.applicant_mobile}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Premisis Address:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{asset.applicant_address}}
                                                        </td>
                                                        <td>
                                                            <b>Ward:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{asset.ward}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Department:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{asset.department}}
                                                        </td>
                                                        <td>
                                                            <b>Category:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{asset.category}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Grievance Description:</b>
                                                        </td>
                                                        <td style="text-align:left;" colspan="3">
                                                            {{asset.grievance_detail}}
                                                        </td>                                                       
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Grievance Source:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{asset.grievance_source}}
                                                        </td>
                                                        <td>
                                                            <b>Grievance Status:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{asset.grievance_status}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Grievance Status Remark:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{asset.grievance_status_remark}}
                                                        </td>  
                                                        <td>
                                                            <b>Grievance Status Entered On:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{asset.status_on}}</td>                                                     
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" align="right">
                                                            <br /><br /><br />
                                                            <b>Executive Officer/ Secretary</b>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>    
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
<form [formGroup]="grievanceForm">
    <div class="row"    >
        <div class="col-md-6">
            <b>Grievance By :</b>{{selGrievance.applicant_name}}
        </div>
        <div class="col-md-6">
            <b>Grievance Department :</b>{{selGrievance.department}}
        </div>
    </div>
    <div class="row"    >
        <div class="col-md-12">
            <div class="form-group">
                <label for="architectDocument">Uploaded Documents:</label>
                <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small; font-weight: bold;">
                    <thead class="cf">
                        <tr>
                            <th>S.No.</th>
                            <th>Document Type</th>
                            <th>Document Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of selGrievance.attachments;let ind=index;">
                            <td>{{ind+1}}</td>
                            <td>{{doc.imageCaption}}</td>
                            <td>{{doc.imageName}}</td>
                            <td><a [href]="doc.firebaseDownloadUrl" target="_blank">View</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="selGrievanceId && selviewtype=='Pending'">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Status<span class="required">*</span></label>
                <select class="form-control" formControlName="grievance_status">
                    <option value="Pending">Pending</option>
                    <option value="Resolved">Resolved</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Remark<span class="required">*</span></label>
                <input class="form-control" formControlName="grievance_remark" />
            </div>
        </div>
    </div>
    <div class="row" *ngIf="selGrievanceId && selviewtype=='Pending'">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Photo<span class="required">*</span></label>
                <app-file-upload [accept]="'image/*'"  [maxFiles]="1" [captionhidestatus]="true" [datehidestatus]="false" [maxSizePerFile]="1048576" [selectedFiles]="grievanceFile"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
            </div>
        </div>
    </div>
    <div class="row" style="padding-top: 20px;" *ngIf="selGrievanceId && selviewtype=='Pending'">
        <div class="col-md-4">
            <button class="btn btn-primary" type="submit" (click)="saveGrievanceStatus()">दर्ज करें</button>
        </div>
        <div class="col-md-8" style="padding-top: 10px;">
            <!-- Additional content if needed -->
        </div>
    </div>
    <div class="row" *ngIf="selGrievanceId && selviewtype=='Marking'">
        <div class="col-md-6">
            <div class="form-group">
                <label class="control-label">Marked To<span class="required">*</span></label>
                <select class="form-control" formControlName="grievance_markto">
                    <option value="eomanikpur@gmail.com">Officer 1</option>
                    <option value="intecco.diwakar@gmail.com">Officer 2</option>
                    <option value="smilingbrains2020@gmail.com">Officer 3</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row" style="padding-top: 20px;" *ngIf="selGrievanceId && selviewtype=='Marking'">
        <div class="col-md-4">
            <button class="btn btn-primary" type="submit" (click)="saveGrievanceMarking()">दर्ज करें</button>
        </div>
        <div class="col-md-8" style="padding-top: 10px;">
            <!-- Additional content if needed -->
        </div>
    </div>
</form>

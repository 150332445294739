import { Injectable } from '@angular/core';  
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';  
import { Observable, retry,catchError, throwError, timeout, map  } from 'rxjs';  
import { StorageService } from 'src/app/login/storage.service';
import { environment } from 'src/environments/environment';

const Service_API = environment.apiUrl+'/PanchayatAPI/GetRequestData?providerid=&provideremailid=&LoginIdentity=&AppVersion=1&providermobile=9984222409&';
const ServicePOST_API = environment.apiUrl+'/PanchayatAPI/PostRequestData?providerid=&provideremailid=&LoginIdentity=&AppVersion=1&providermobile=9984222409&';
const httpOptions = {
   headers: new HttpHeaders().set("Access-Control-Allow-Origin", "*")
   .set("Access-Control-Allow-Methods", "OPTIONS, GET, POST")
   .set("Content-Type", "application/json")
 };
@Injectable({
   providedIn: 'root',
 })  
export class PanchayatService {  
   userlogin:any;	
   constructor(private http: HttpClient, private storageService: StorageService) { 
      this.userlogin=this.storageService.getUser();
      if(this.userlogin){ }
      else{this.userlogin={"State_id":"0","District_id":"0"};}
   }    
   getLevel2s(): any[] {    
    let lstLevel2 =[{"Level2_id":"6","Level2_Name":"चौरही"},
    {"Level2_id":"7","Level2_Name":"बजहाभीट"},
    {"Level2_id":"8","Level2_Name":"भोला का पुरवा"},
    {"Level2_id":"9","Level2_Name":"मिरगडवा"},
    {"Level2_id":"10","Level2_Name":"अदलपुर"},
    {"Level2_id":"11","Level2_Name":"हुलास राय का पुरवा"},
    {"Level2_id":"12","Level2_Name":"लाटतारा"},
    {"Level2_id":"13","Level2_Name":"रानापट्टी"},
    {"Level2_id":"14","Level2_Name":"बभनपुर"},
    {"Level2_id":"15","Level2_Name":"चौकापारपुर"},
    {"Level2_id":"16","Level2_Name":"मनिहारी टोला"},
    {"Level2_id":"17","Level2_Name":"खानकाह"},
    {"Level2_id":"10006","Level2_Name":"काछीपटटी"},
    {"Level2_id":"10007","Level2_Name":"सुल्तानपुर"},
    {"Level2_id":"10008","Level2_Name":"मां ज्वाला देवी"},
    {"Level2_id":"10009","Level2_Name":"मानिकपुर"},
    {"Level2_id":"10010","Level2_Name":"शाहाबाद"}];
    return lstLevel2;
  }  
   GetSummaryData(filterState:any,isdatefilter:any): Observable<any> {  	         
      const headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set("Access-Control-Allow-Methods", "OPTIONS, GET, POST")
      .set("Content-Type", "application/json");
	  return this.http.get(Service_API+'methodname=SurveySummary&jsondata={'
     + '"fromdate":' + (isdatefilter?filterState.selectedDateRange.startDate.format("YYYY-MM-DD"):'""') + ',"todate":' + (isdatefilter?filterState.selectedDateRange.endDate.format("YYYY-MM-DD"):'""')
     + ',"Level1ID":' + filterState.SelLevel1ID + ',"Level2ID":' + filterState.SelLevel2ID + ',"AssetType":}',  httpOptions);  
   } 
   getAssetData(indctr:any, filterState:any,isdatefilter:any,pageno: number, pagesize: number): Observable<any> {
      return this.http.get(Service_API+'methodname='+indctr+'&page='+pageno+'&pagesize='+pagesize+'&jsondata={'
      + '"fromdate":' + (isdatefilter?filterState.selectedDateRange.startDate.format("YYYY-MM-DD"):'""') + ',"todate":' + (isdatefilter?filterState.selectedDateRange.endDate.format("YYYY-MM-DD"):'""')
      + ',"Level1ID":"'+ filterState.SelLevel1ID+'","Level2ID":"'+filterState.SelLevel2ID+'","AssetType":"","Searchkeyword":"'+filterState.SearchKeyword+'"}', { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }), responseType: 'text' })
      .pipe(
         timeout(60000)
       );
   }
   getTaxData(indctr:any,SurveyJsonID:any): Observable<any> {
    return this.http.get(Service_API+'methodname='+indctr+'&page=&pagesize=&jsondata={'
    + '"SurveyJsonID":"' + SurveyJsonID +'"}', { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }), responseType: 'text' })
    .pipe(
       timeout(60000)
     );
  }
  SaveTaxData(indctr:any,jsondata:any): Observable<any> {
    return this.http.post(ServicePOST_API+'methodname='+indctr+'&page=&pagesize=&jsondata=' + jsondata, { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }), responseType: 'text' })
    .pipe(
       timeout(60000)
     );
  }
  getOtherData(indctr:any): Observable<any> {
    return this.http.get(Service_API+'methodname='+indctr+'&page=&pagesize=&jsondata={}', { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }), responseType: 'text' })
    .pipe(
       timeout(60000)
     );
  }
  // Error handling
   private handleError(error: HttpErrorResponse): Observable<never> {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Client-side error
       errorMessage = `Client-side error: ${error.error.message}`;
     } else {
       // Server-side error
       errorMessage = `Server-side error: ${error.status} ${error.message}`;
     }
     console.error(errorMessage);
     return throwError(errorMessage);
   }   
}  

<div class="row">
    <div class="col-md-12">
        <div class="heading">
            <h4>Pending House Tax<small></small></h4>
        </div>
    </div>
    <div class="col-md-12">
        <div id="no-more-tables">
            <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small;font-weight:bold">
                <thead class="cf">
                    <tr>
                        <th>S.No.</th>
                        <th>Ward</th>
                        <th>Property ID</th>
                        <th>Applicant Name</th>
                        <th>Father/Husband Name</th>
                        <th>Address</th>
                        <th>Plot Area</th>
                        <td>Financial Year</td>
                        <td>Tax Amount</td>
                        <td>Arrear</td>
                        <td>Interest</td>
                        <td>Total Pending Amount</td>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let htax of rptHousetax; let i = index">
                        <tr>
                            <td>{{i + 1}}</td>
                            <td>{{htax.OwnerWard}}</td>
                            <td>{{htax.PropertyID}}</td>
                            <td>{{htax.OwnerName}}</td>
                            <td>{{htax.OwnerFatherName}}</td>
                            <td>{{htax.OwnerAddress}}</td>
                            <td>{{htax.PropertyArea}}</td>
                            <td>{{htax.FinancialYear}}</td>
                            <td>{{htax.currentyeartax}}</td>
                            <td>{{htax.arrear}}</td>
                            <td>{{htax.interest}}</td>
                            <td>{{htax.taxamount}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin.routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared/shared.module';
import { TaxationListComponent } from './taxation-list/taxation-list.component';
import { PagerModule } from './pager/pager.module';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CountUpModule } from 'ngx-countup';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';

import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import {FileUploadModule} from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { StatsModule } from './stats/stats.module';
import { AdminNavbarModule } from 'src/app/modules/admin/adminnavbar/adminnavbar.module';
import { FooterModule } from './footer/footer.module';
import { QRCodeModule } from 'angularx-qrcode';
import { PariwarRegisterComponent } from './pariwar-register/pariwar-register.component';
import { ApplicantProfileComponent } from './applicant-profile/applicant-profile.component';
import { ShareService } from 'src/app/shared/services/shared.service';
import { BuildingApprovalComponent } from './building-approval/building-approval.component';
import { TradeLicenseComponent } from './trade-license/trade-license.component';
import { AssetLeaseComponent } from './asset-lease/asset-lease.component';
import { GrievanceComponent } from './grievance/add-grievance/grievance.component';
import { HouseTaxComponent } from './house-tax/house-tax.component';
import { WaterTaxComponent } from './water-tax/water-tax.component';
import { FileUploadComponent } from 'src/app/shared/components/file-upload/file-upload.component';
import { GrievanceRaiserFormComponent } from './grievance/grievance-raiser-form/grievance-raiser-form.component';
import { MutationComponent } from './mutation/mutation.component';
import { ReportHouseTaxComponent } from './report-house-tax/report-house-tax.component';
import { ReportWaterTaxComponent } from './report-water-tax/report-water-tax.component';

@NgModule({
    declarations: [        
        AdminLayoutComponent,
        TaxationListComponent,
        PariwarRegisterComponent,
        ApplicantProfileComponent,
        BuildingApprovalComponent,
        TradeLicenseComponent,
        AssetLeaseComponent,
        GrievanceComponent,
        HouseTaxComponent,
        WaterTaxComponent,
        MutationComponent,
        GrievanceComponent,   
        FileUploadComponent,
        GrievanceRaiserFormComponent  ,
        ReportHouseTaxComponent,
        ReportWaterTaxComponent
    ],
    imports: [
        AdminRoutingModule,
        CommonModule,
        SharedModule,
        FontAwesomeModule,
        PagerModule,
        FooterModule,
        AdminNavbarModule,
        StatsModule,        
        FormsModule,
        ReactiveFormsModule,
        CountUpModule,       
        //NgbModule,
        NgCircleProgressModule.forRoot({
        "radius": 80,
        "space": 4,
        "showInnerStroke":true,
        "outerStrokeWidth": 15,
        "innerStrokeWidth": 5,
        "titleFontSize":"28",
        "subtitleFontSize":"18",
        "animateTitle": false,
        "showSubtitle": true,
        "animationDuration": 1000,
        "showUnits": false,
        "showBackground": false,
        "startFromZero": true,
        "lazy": true,
        "responsive": true
        }),        
        //Prime NG
        ProgressSpinnerModule,    
        DialogModule,
        DynamicDialogModule,
        TableModule,
        ButtonModule,
        RadioButtonModule,
        ToastModule,
        TabViewModule,
        MultiSelectModule,
        SelectButtonModule,
        FileUploadModule,
        TooltipModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule, 
        QRCodeModule,
    ],
    providers: [ShareService]
})
export class AdminModule { }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WebFooterComponent } from './webfooter.component';

@NgModule({
    imports: [ RouterModule, CommonModule,FormsModule,        
     ],
    declarations: [ WebFooterComponent ],
    exports: [ WebFooterComponent ]
})

export class WebFooterModule {}

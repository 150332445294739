<!-- <span fx Flex>
    <mat-icon >close</mat-icon>
</span> -->

<div class="full-container">
    <div class="toolbar-header">
        <h2>Confirm</h2>
        <span>
            <mat-icon class="iconCls" (click)="onClose(false)">close</mat-icon>
        </span>

    </div>
    <div class="content-container">
        <span class="conf-text">{{data.title}}</span>

        <div class="action-btns">
            <button  mat-raised-button  style="margin-right: 15px;"(click)="onClose(false)">Cancel</button>
            <button type="submit" mat-raised-button color="primary" (click)="onClose(true)" >Save</button>
        </div> 


    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-water-tax',
  templateUrl: './water-tax.component.html',
  styleUrl: './water-tax.component.css',
  providers:[DatePipe,ExcelService]
})
export class WaterTaxComponent implements OnInit {
  WaterTax: any[] = [];
  PanchayatID:any="Manikpur";
  ispendinglistloaded:boolean=false;
  pendingWaterTax: any[] = [];
  currentyeartax:any=0;
  expandedIndex: number | null = null;
  WaterTaxForm: FormGroup;
  SurveyJsonID:any;
  selectedPaymentMethod:any="";
  constructor(public ref: DynamicDialogRef,public config: DynamicDialogConfig ,private fb: FormBuilder, private panchayatservice: PanchayatService) {
    this.SurveyJsonID=this.config.data.surveyjsonid;
    this.WaterTaxForm = this.fb.group({
      FinancialYear: ['', Validators.required],
      SurveyJsonID: [this.SurveyJsonID, Validators.required],
      transactionNumber: [''],
      referenceNumber: [''],
      PreviousBalance: ['', Validators.required],
      currentyeartax: ['', Validators.required],
      arrear: ['', Validators.required],
      interest: ['', Validators.required],
      discount: ['', Validators.required],
      paidAmount: ['', Validators.required],
      paymentMethod: ['', Validators.required],
      chequeBankName: [''],
      transactionDate: [''],
    });
  }

  ngOnInit(): void {
    this.getTaxData(this.SurveyJsonID); // Replace with appropriate SurveyJsonID
    this.getPendingWaterTaxData(this.SurveyJsonID); // Replace with appropriate SurveyJsonID
  }
  closedialog():void{
    this.ref.close();
  }
  onPaymentMethodChange(event: Event): void {
    this.selectedPaymentMethod = this.WaterTaxForm.controls['paymentMethod'].value;
    this.WaterTaxForm.controls['referenceNumber'].setValue('');
    this.WaterTaxForm.controls['chequeBankName'].setValue('');
    this.WaterTaxForm.controls['transactionDate'].setValue('');
  }
  expandedMap: { [key: number]: boolean } = {};
  toggleExpand(assetId: number) {
    this.expandedMap[assetId] = !this.expandedMap[assetId];
  }

  isExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }

  getTaxData(surveyJsonID: number): void {
    this.panchayatservice.getTaxData("WaterTax",surveyJsonID).subscribe((data: any) => {
      if(data.length>0){        
        //console.log(data)
        this.WaterTax = JSON.parse(data).ResponseData;
      }           
    });    
  }

  getPendingWaterTaxData(surveyJsonID: number): void {
    this.ispendinglistloaded=true;
    this.panchayatservice.getTaxData("InitialWaterTax",surveyJsonID).subscribe((data: any) => {
      if(data.length>0){        
        //console.log(data)
        this.pendingWaterTax = JSON.parse(data).ResponseData;
        if(this.pendingWaterTax){
          if(this.pendingWaterTax.length>0){
            this.WaterTaxForm.controls['currentyeartax'].setValue(parseFloat(this.pendingWaterTax[0].currentyeartax));
            this.WaterTaxForm.controls['arrear'].setValue(parseFloat(this.pendingWaterTax[0].arrear));
            this.WaterTaxForm.controls['interest'].setValue(parseFloat(this.pendingWaterTax[0].interest));
            this.WaterTaxForm.controls['FinancialYear'].setValue(this.pendingWaterTax[0].FinancialYear);
          }
        }
        //for(let i = 0; i < this.pendingWaterTax.length; i++) {
          //this.WaterTaxForm.controls['currentyeartax'].setValue(this.WaterTaxForm.controls['currentyeartax'].value + parseFloat(this.pendingWaterTax[i].taxamount));
        //}        
      }
      this.ispendinglistloaded=false;           
    });    
  }

  calculateFinalPaymentWater(): number {
    // Add your logic here to calculate final payment
    let prevbal=0;let curtax=0;let arrear=0;let interest=0;let discount=0;
    if(this.WaterTaxForm.controls['PreviousBalance'].value!="")prevbal=parseFloat(this.WaterTaxForm.controls['PreviousBalance'].value);
    if(this.WaterTaxForm.controls['currentyeartax'].value!="")curtax=parseFloat(this.WaterTaxForm.controls['currentyeartax'].value);
    if(this.WaterTaxForm.controls['arrear'].value!="")arrear=parseFloat(this.WaterTaxForm.controls['arrear'].value);
    if(this.WaterTaxForm.controls['interest'].value!="")interest=parseFloat(this.WaterTaxForm.controls['interest'].value);
    if(this.WaterTaxForm.controls['discount'].value!="")discount=parseFloat(this.WaterTaxForm.controls['discount'].value);
    return curtax+prevbal+arrear+interest-discount;
  }
  submitted:boolean=false;
  onSubmit(): void {    
    this.submitted=true;
    let pendwatertax:any[]=[];
    for(var m=0;m<this.pendingWaterTax.length;m++){
      pendwatertax.push({"SurveyJsonID":this.pendingWaterTax[m].SurveyJsonID,"FinancialYear":this.pendingWaterTax[m].FinancialYear});
    }
    if (this.WaterTaxForm.valid) {
      const formValues = this.WaterTaxForm.value;
      const submissionData = {
        ...formValues,
        pendingWaterTaxJson:pendwatertax,
        totaltax:this.calculateFinalPaymentWater()
      };      
      console.log(submissionData); 
      this.panchayatservice.SaveTaxData("SaveWaterTax",JSON.stringify(submissionData)).subscribe((data: any) => {        
        this.submitted=false;
        if(JSON.parse(data).resMessage.includes("Successfully")){
          this.getTaxData(this.SurveyJsonID);
          this.WaterTaxForm.controls['transactionNumber'].setValue('');
          this.WaterTaxForm.controls['PreviousBalance'].setValue('');
          this.WaterTaxForm.controls['currentyeartax'].setValue('');
          this.WaterTaxForm.controls['arrear'].setValue('');
          this.WaterTaxForm.controls['interest'].setValue('');
          this.WaterTaxForm.controls['discount'].setValue('');
          this.WaterTaxForm.controls['paidAmount'].setValue('');
          this.WaterTaxForm.controls['paymentMethod'].setValue('');
          this.WaterTaxForm.controls['referenceNumber'].setValue('');
          this.WaterTaxForm.controls['chequeBankName'].setValue('');
          this.WaterTaxForm.controls['transactionDate'].setValue('');
          this.pendingWaterTax = [];
        }
      });
    }    
  }
  printDiv(divName: string) {
    let filename ='Tax Slip'+'.pdf';
    let PdfDiv = document.getElementById(divName);
    const content = PdfDiv?.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="http://smilingbrains.com/assets/css/style.css?v=2.0" rel="stylesheet" />');
    printWindow?.document.write(`<style>
    table {
      border-collapse:collapse;
    }
    
    table tr td {
      border: solid black 1px;
      padding: 5px 7px;
    }
    
    table tr th {
      border: none;
      padding-top: 5px;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }  
}

import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { WebHomeComponent } from 'src/app/modules/home/webhome/webhome.component';
import { NgModule } from '@angular/core';
import { LoginComponent } from 'src/app/login/login.component';
import { WebGalleryComponent } from 'src/app/modules/home/webgallery/webgallery.component';
import { WebWardListComponent } from 'src/app/modules/home/wardlist/webwardlist.component';
import { WebMemberListComponent } from 'src/app/modules/home/memberlist/webmemberlist.component';
import { WebFacilitiesComponent } from 'src/app/modules/home/webfacilities/webfacilities.component';
import { WebDepartmentComponent } from 'src/app/modules/home/departmentlist/webdepartment.component';
import { WebContactusComponent } from 'src/app/modules/home/webcontactus/webcontactus.component';

export const routes: Routes = [
    { path: '',
    component: HomeLayoutComponent,
    children: [
      { path: '', component: WebHomeComponent },
      { path: 'webhome', component: WebHomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'gallery', component: WebGalleryComponent },
      { path: 'wardlist', component: WebWardListComponent },
      { path: 'memberlist', component: WebMemberListComponent },
      { path: 'departments', component: WebDepartmentComponent },
      { path: 'services', component: WebFacilitiesComponent },
      { path: 'contactus', component: WebContactusComponent }
      // other routes for the default layout
    ]}
  ];
  
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  
  export class HomeLayoutRoutingModule { }
  
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AssetData } from '../../../shared/interfaces/assetdata.interface'; // Ensure the correct path
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TaxationListComponent } from '../taxation-list/taxation-list.component';
import { MessageService } from 'primeng/api';
import { ApplicantProfileComponent } from '../applicant-profile/applicant-profile.component';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-pariwar-register',
  // standalone: false,
  // imports: [],
  templateUrl: './pariwar-register.component.html',
  styleUrl: './pariwar-register.component.css',
  providers:[DatePipe,ExcelService,DialogService,MessageService ]
})
export class PariwarRegisterComponent  implements OnInit {
  currentDate: any="";
  PanchayatID:any="Manikpur";
  assetList: any[] = []; // Initialize with the actual data
  paginatedAssetList: any[] = [];
  expandedMap: { [key: number]: boolean } = {};
  recordSize = 100;
  recordPerPageValues = [50,100,200,300];
  totalCount = 100; // Set this to actual total count
  currentPage = 1;
  filterState: any = {};
  private filterStateSubscription!: Subscription;
  ref: DynamicDialogRef | undefined;
  constructor(private shareService:ShareService,public messageService:MessageService,public dialogService: DialogService,private excelService: ExcelService,private datePipe: DatePipe,private panchayatservice: PanchayatService,private filterService: FilterService) {}
  ngOnInit(): void {
    this.currentDate = this.datePipe.transform(new Date(), 'dd MMM yyyy');
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
     // Update filter state with the changed value
     this.filterState = filterData.filterState; 
     // Call the method in other component to handle the filter changes
     if(this.filterService.isInitialized()){
     //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.getTaxations();
        this.setRecordPerPage(this.recordSize);
     }
    });                 
  }
  getTaxations(): void {
    this.shareService.setParam(true);
    this.assetList = [];
    this.totalCount=0;
    this.panchayatservice.getAssetData("PariwarData",this.filterState,false,this.currentPage,this.recordSize).subscribe((data: any) => {
      if(data.length>0){        
        //console.log(data)
        this.assetList = JSON.parse(data).ResponseData=='No Record'?[]:JSON.parse(data).ResponseData;
        this.totalCount=  JSON.parse(data).ResponseCount;
        this.shareService.setParam(false);
      }           
    });    
  }
  openProfileModal(Selasset:any,popuptype:any):void {
    this.ref = this.dialogService.open(ApplicantProfileComponent, { 
        data: {
            assetdata: Selasset,
            profiletype:popuptype
        },        
        width: '70%',
        closeOnEscape:true,
        contentStyle: { overflow: 'auto','padding-top':'15px','max-height': '500px',  },
        maximizable: false
    });
    this.ref.onClose.subscribe((assetdata: any) => {
      if (assetdata) {
          //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: assetdata.FamilyOwnerName });
      }
    });
  }
  ngOnDestroy() { 
    if (this.ref) { 
        this.ref.close(); 
    } 
  } 
  exportToExcel(event:any) {
    this.excelService.exportAsExcelFile(this.assetList, 'pariwardata');
  }  
  toggleExpand(assetId: number) {
    this.expandedMap[assetId] = !this.expandedMap[assetId];
  }
  
  setRecordPerPage(size: number) {
    this.recordSize = size;
    this.currentPage = 1; // Reset to the first page
    this.updatePaginatedList();
  }

  onPageChanged() {
    this.updatePaginatedList();
  }

  updatePaginatedList() {
    // const startIndex = (this.currentPage - 1) * this.recordSize;
    // const endIndex = startIndex + this.recordSize;
    // this.paginatedAssetList = this.assetList.slice(startIndex, endIndex);
    this.getTaxations();
  }

}
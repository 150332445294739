<h2 class="innerh2">विभाग </h2><br />

<section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">


    <div class="container" style="margin-top: 20px;">
        <div class="list-group" style="margin-top: 10px;">
            <a href="#/hm/departments" class="list-group-item">लोक निर्माण सेवा</a>
            <a href="#/hm/departments" class="list-group-item">जन स्वास्थ्य सेवाएं</a>
            <a href="#/hm/departments" class="list-group-item">कर विभाग</a>
            <a href="#/hm/departments" class="list-group-item">प्रमुख गतिविधि विभाग </a>
            <a href="#/hm/departments" class="list-group-item">स्ट्रीट लाइट </a>
            <a href="#/hm/departments" class="list-group-item">नगर-योजना</a>
            <a href="#/hm/departments" class="list-group-item">नगर विकास</a>
            <a href="#/hm/departments" class="list-group-item">जल विकास </a>
            <a href="#/hm/departments" class="list-group-item">हाइड्रोलिक</a>
        </div>
    </div>

</section>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTH_API = environment.apiUrl+'/PanchayatAPI/GetRequestData?providerid=&provideremailid=&LoginIdentity=&AppVersion=1&providermobile=9984222409&';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
	  //return this.http.post(loginUrl, { username, password });
    return this.http.get(
      AUTH_API+'methodname=VerifyUser&page=1&pagesize=1&jsondata={"Username":"'+username+'","Password":"'+password+'"}',
      httpOptions
    );
    
  }
  GetPermission(logintype:any): Observable<any> {  	
    return this.http.get(AUTH_API+'DashboardSummary.php?AppVersion=2&indctr=PermissionSummary&methodtype=GETTING&logintype='+logintype,      
     httpOptions
     );  
  }
 
  logout(): Observable<any> {
    return this.http.post(AUTH_API + 'signout', { }, httpOptions);
  }
}

<h2 class="innerh2">सभासद सूची </h2><br />

<section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">


    <div class="container" style="margin-top: 20px;">
        <div class="row d-flex justify-content-between">
            <div class="col-md-9">
                <div class="ward">                   
                    <table width="95%">
                        <thead>
                            <tr>
                                <th>वार्ड न०</th>
                                <th>वार्ड का नाम</th>
                                <th>सभासद का नाम</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>1</td>
                            <td>
                        चौरही</td>
                        <td>

वीरेन्द्र कुमार</td></tr>
<tr>
<td>

2</td>
<td>

बजहाभीट</td>
<td>

चंदा</td></tr>
<tr>
<td>

3</td>
<td>

भोला का पुरवा</td>
<td>

अर्जुन कुमार</td></tr>
<tr>
<td>

4</td>
<td>

मिरगडवा</td>
<td>

शोभा</td></tr>
<tr>
<td>

5</td>
<td>

अदलपुर</td>
<td>

कृष्णा</td></tr>
<tr>
<td>

6</td>
<td>

हुलास राय का पुरवा</td>
<td>

पन्ना लाल सोनकर</td></tr>
<tr>
<td>

7</td>
<td>

लाटतारा</td>
<td>

जगनाथ</td></tr>
<tr>
<td>

8</td>
<td>

रानापट्टी</td>
<td>

धर्मचन्द्र</td></tr>
<tr>
    <td>9</td>
<td>

बभनपुर</td>
<td>

ललित जायसवाल</td></tr>
<tr>
<td>

10</td>
<td>

चौकापारपुर</td>
<td>

रमेश सिंह</td></tr>
<tr>
<td>

11</td>
<td>

मनिहारी टोला</td>
<td>

दिलीप कुमार निषाद</td></tr>
<tr>
<td>

12</td>
<td>

खानकाह</td><td>

    रूकसाना बानो</td></tr>
<tr>
<td>

13</td>
<td>

काछीपटटी</td>

<td>

रमेश मौर्या</td></tr>
<tr>
    <td>14</td>
<td>

सुल्तानपुर</td>
<td>

मो० साहिद</td></tr>
<tr>
<td>

15</td>
<td>

मां ज्वाला देवी</td>
<td>

मो० अनस</td></tr>
<tr>
<td>

16</td>
<td>

मानिकपुर</td>
<td>

विनोद शंकर</td></tr>
<tr>
<td>

17</td>
<td>

शाहाबाद</td>
<td>

कुसुमा देवी</td></tr>
                            <td>
                    </table>
                    <br/><br/>
                </div>
            </div>
            
        </div>
    </div>

</section>

<div class="row">
    <div class="col-md-12">
        <div class="heading">
            <h4>Mutation<small></small></h4>
        </div>
    </div>
    <div class="col-md-12">
        <div id="no-more-tables">
            <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small;font-weight:bold">
                <thead class="cf">
                    <tr>
                        <th>S.No.</th>
                        <th>Owner Name</th>
                        <th>Owner Father/Husband Name</th>
                        <th>Occupier Name</th>
                        <th>Occupier Father/Husband Name</th>
                        <th>Mobile No.</th>
                        <th>Address</th>
                        <th>Email ID</th>
                        <th>Cause of mutation</th>
                        <th>Sale Deed amount</th>
                        <th>Mutation Fees</th>
                        <th>Entered On</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let mutlist of MutationList; let i = index">
                        <tr (click)="toggleExpand(mutlist.MutationID)">
                            <td>{{i + 1}}</td>
                            <td>{{mutlist.OwnerName}}</td>
                            <td>{{mutlist.OwnerFatherName}}</td>
                            <td>{{mutlist.occupier_name}}</td>
                            <td>{{mutlist.occupier_fathers_husbands_name}}</td>
                            <td>{{mutlist.occupier_mobile_number}}</td>
                            <td>{{mutlist.occupier_address}}</td>
                            <td>{{mutlist.occupier_emailid}}</td>
                            <td>{{mutlist.mutationCause}}</td>
                            <td>{{mutlist.SaleDeedAmount}}</td>
                            <td>{{mutlist.mutationFee}}</td>
                            <td>{{mutlist.enteredon}}</td>
                        </tr> 
                        <tr *ngIf="expandedMap[mutlist.MutationID]" class="fold open">
                            <td colspan="7" class="m-srp-card">
                                <div class="row">
                                    <div class="col-md-12" style="text-align:right">
                                        <button class="btn btn-warning" (click)="printDiv('dvhousetaxprint')">Print</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" id="dvhousetaxprint">
                                        <div class="row">
                                            <div class="col-md-12" id="dvhousetaxprint">
                                                <table style="font-size: small;font-weight:bold;width:600px;border:1px solid black;">
                                                    <tr>
                                                        <td colspan="4" style="text-align:center;">
                                                            <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG" style="width:600px;">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" style="text-align:center;">
                                                            <strong> नाम परिवर्तन रशीद </strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"><qrcode
                                                            qrdata="{{mutlist.OwnerName}} | {{mutlist.applicant_name}} | {{mutlist.mutationCause}} | {{mutlist.mutationFee}} | {{mutlist.enteredon}}"
                                                            [allowEmptyString]="true"
                                                            [cssClass]="'center'"
                                                            [colorDark]="'#000000ff'"
                                                            [colorLight]="'#ffffffff'"
                                                            [elementType]="'img'"
                                                            [errorCorrectionLevel]="'M'"
                                                            [scale]="1"
                                                            [width]="80"
                                                            ></qrcode></td>
                                                        <td style="text-align:right;" colspan="2">
                                                            <strong>Reference Number :</strong>MT2024-{{mutlist.MutationID}}<br/>                                                                            
                                                            <strong>Date:</strong>{{mutlist.enteredon}}<br/>                                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:25%">
                                                            <b>Old Owner Name:</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{mutlist.OwnerName}}
                                                        </td>
                                                        <td style="width:25%">
                                                            <b>Old Owner Father/Husband Name</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{mutlist.OwnerFatherName}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:25%">
                                                            <b>New Owner Name:</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{mutlist.applicant_name}}
                                                        </td>
                                                        <td style="width:25%">
                                                            <b>New Owner Father/Husband Name</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{mutlist.fathers_husbands_name}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Premisis Address:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{mutlist.applicant_address}}
                                                        </td>
                                                        <td>
                                                            <b>Ward:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{mutlist.ward}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Mobile Number:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{mutlist.mobile_number}}
                                                        </td>
                                                        <td>
                                                            <b>Email ID:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{mutlist.emailid}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Mutation Cause:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{mutlist.mutationCause}}
                                                        </td>
                                                        <td>
                                                            <b>Mutation Fee:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{mutlist.mutationFee}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" align="right">
                                                            <br /><br /><br />
                                                            <b>Executive Officer/ Secretary</b>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>                       
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-md-12"> 
        <form [formGroup]="mutationForm">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="font-red">
                    <!-- Update Data ID : {{selectsForm.controls['UpdateID'].value}} -->
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="mobileNumber">Occupier Mobile Number:</label>
                    <input id="mobileNumber" class='form-control' formControlName="mobileNumber" type="text"  class='form-control'>
                </div>
            </div>
            
            <div class="col-md-4">
                <div class="form-group">
                    <label for="aadharCardNumber">Aadhar Card Number:</label>
                    <input id="aadharCardNumber" formControlName="aadharCardNumber" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-4">
                <div class="form-group">
                    <label for="applicantName">Applicant Name:</label>
                    <input id="applicantName" formControlName="applicantName" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-4">
                <div class="form-group">
                    <label for="fathersHusbandsName">Father's/Husband's Name:</label>
                    <input id="fathersHusbandsName" formControlName="fathersHusbandsName" type="text"  class='form-control' >
                </div>
            </div>            
            <div class="col-md-4">
                <div class="form-group">
                    <label for="correspondenceAddress">Correspondence Address:</label>
                    <input id="correspondenceAddress" formControlName="correspondenceAddress" type="text"  class='form-control' >
                </div>
            </div>  
            <div class="col-md-4">
                <div class="form-group">
                    <label for="correspondenceAddress">Email ID:</label>
                    <input id="correspondenceAddress" formControlName="emailid" type="text"  class='form-control' >
                </div>
            </div>            
            <div class="col-md-4">
                <div class="form-group">
                    <label for="mutationCause">Cause of mutation:</label>
                    <select id="mutationCause" class="form-control" formControlName="mutationCause">
                        <option value="">-Select-</option>
                        <option value="DUE TO DEATH OF RECORDED OWNER">DUE TO DEATH OF RECORDED OWNER</option>
                        <option value="DUE TO REGISTERED SALE DEED">DUE TO REGISTERED SALE DEED</option>
                        <option value="WILL DEED">WILL DEED</option>
                        <option value="REGISTERED GIFT DEED">REGISTERED GIFT DEED</option>
                        <option value="FAMILY SATTLEMENT">FAMILY SATTLEMENT</option>
                        <option value="BY ORDER OF COURT">BY ORDER OF COURT</option>
                        <option value="HIBBA">HIBBA</option>                
                    </select>
                </div>
            </div>            
            <div class="col-md-4">
                <div class="form-group">
                    <label for="SaleDeedAmount">Sale Deed Amount:</label>
                    <input id="SaleDeedAmount" formControlName="SaleDeedAmount" type="text"  class='form-control'>
                </div>
            </div>        
            <div class="col-md-4">
                <div class="form-group">
                    <label for="mutationFee">Mutation Fees:</label>
                    <input id="mutationFee" formControlName="mutationFee" type="text"  class='form-control'>
                </div>
            </div>   
            <div class="col-md-12">
                <div class="form-group">
                    <label for="architectDocument">Uploaded Documents:</label>
                    <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small; font-weight: bold;">
                        <thead class="cf">
                            <tr>
                                <th>S.No.</th>
                                <th>Document Type</th>
                                <th>Document Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let doc of selUploadedDocuments;let ind=index;">
                                <td>{{ind+1}}</td>
                                <td>{{doc.imageCaption}}</td>
                                <td>{{doc.imageName}}</td>
                                <td><a [href]="doc.firebaseDownloadUrl" target="_blank">View</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>     
            <div class="col-md-12">
                <div class="form-group">
                    <label for="Documents">Documents : Proposed Owner Image,Scan Copy of Id Proof(Proposed Owner),Scan Copy of Affidavit,Relative Mutation Evidence</label>
                    <app-file-upload [accept]="'image/*'"  [maxFiles]="4" [captionhidestatus]="true" [datehidestatus]="false" [maxSizePerFile]="1048576" [selectedFiles]="mutationFile"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
                </div>
            </div>            
            <div class="col-md-12">
                <button type="submit" class="m-srp-card__btn m-srp-card__btn--primary-o" [disabled]="mutationForm.invalid" (click)="saveMutationData()">Submit</button>
            </div>
        </form>
    </div>
    
</div>
import { Location } from '@angular/common';
import { ROUTES } from 'src/app/modules/admin/adminnavbar/adminnavbar.component';
import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { StorageService } from 'src/app/login/storage.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
	providers: [DatePipe],
  encapsulation:ViewEncapsulation.None
})
export class AdminLayoutComponent implements OnInit {
  private listTitles: any[]=[];
  location: Location;
  customrangeshow:boolean=true;
  shocalendaralways:boolean=true;
  curdate: any;
  curpageurl:any='';
	myDate = new Date();
  lstLevel1:any=[];lstLevel2:any=[];lstAssetType:any=[];
  SelLevel1ID:any="0";SelLevel2ID:any="0";SearchKeyword:any="";
  ranges:any;
  userlogin:any;
  issearching:boolean=false;
  isreport:boolean=false;
	constructor(private shareService:ShareService,private datePipe: DatePipe,private panchayatservice:PanchayatService, private storageService: StorageService
    , private router: Router,private filterService: FilterService,private actroute: ActivatedRoute,location: Location) {
      this.location = location;        
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){
      this.curdate = this.datePipe.transform(this.myDate, 'dd MMM yyyy');	   
      console.log(this.userlogin);
      this.getLevel1s();
      this.SelLevel1ID="0";//this.userlogin.Level1ID;
      this.getLevel2s();
      this.getAssetType();
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {   
          this.curpageurl=val.urlAfterRedirects;
          // if(this.curpageurl.includes('tradelicense') || this.curpageurl.includes('assetlease')){
          //   this.isreport=false;
          // }
          // else{
            this.isreport=true;
          // }
          console.log(this.curpageurl);
          // this.LoadDateRange(val.urlAfterRedirects);
          // this.updateFilterValue("selectedDateRange",this.selectedDateRange,true);   
        }
     });
    }
    else{
      this.router.navigate(['/login']);
    }
  }
  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.shareService.sharedData.subscribe(data=> { this.issearching=data })
  }
  updateAllFilterValue(): void {    
    this.filterService.setFilterValue("SelLevel1ID", this.SelLevel1ID,false);
    this.filterService.setFilterValue("SelLevel2ID", this.SelLevel2ID,false);
    this.filterService.setFilterValue("SearchKeyword", this.SearchKeyword,true);
  }
  updateFilterValue(controlName: string, value: string,serviceinitalize:boolean): void {
    if(controlName=="SelLevel1ID"){
      this.filterService.setFilterValue("SelLevel2ID", "0",false);
    }
    this.filterService.setFilterValue(controlName, value,serviceinitalize);
  }
  getLevel1s(): void {
    this.lstLevel1=[{"Level1_id":"7","Level1_Name":"Maanikpur Panchayat"}];
  }
  
  getLevel2s(): void {    
    this.lstLevel2 =this.panchayatservice.getLevel2s();
  }

  getAssetType(): void {
    this.lstAssetType =[];
    this.lstAssetType =[{"AssetType_id":"07DE68A3-F958-4BE2-B264-ABC27FFA0E85.json","AssetType_Name":"Polygon"},
    {"AssetType_id":"A4A29036-C72F-4758-98B4-2E0B34C22FFE.json","AssetType_Name":"Polyline"},
    {"AssetType_id":"824FCB3F-4133-46A2-B3A4-870C5D19E926.json","AssetType_Name":"Point"},
    {"AssetType_id":"5CC14BEE-516A-4709-A133-DFD55398C6D7.json","AssetType_Name":"Pariwar"}];
      // this.rtmisservice.GetMasterData('AssetType', 'main', '1', 'AssetTypeid', this.CheckLoginType('AssetType')?this.userlogin.AssetType_id:'0').subscribe((data: {}) => {
      //   this.lstAssetType = data;
      //   if(this.CheckLoginType("AssetType")){
      //     this.SelAssetTypeID=this.lstAssetType[0].AssetType_id;
      //     this.updateFilterValue("SelAssetTypeID", this.SelAssetTypeID,false);
      //   }
      //   else{
      //     this.SelAssetTypeID="0";
      //   }
      // });   
  }

  CheckLoginType(checkfor:any):boolean{
    var resp :boolean=false;
    if(checkfor==='AssetType'){
      resp = this.userlogin.logintype=='AssetType';
    }
    if(checkfor==='Level2'){
      resp = this.userlogin.logintype=='Level2';
    }
    if(checkfor==='Level1'){
      resp = this.userlogin.logintype=='Level1' || this.userlogin.logintype=='Level2';
    }
    return resp;
  }
  fs_day !:any;fe_day !:any;
  LoadDateRange(pageurl:any):void{
    var s_finyear:any = new Date().getFullYear();
    var e_finyear:any = new Date().getFullYear();
    var curmonth:any = new Date().getMonth()+1;
    if (curmonth <= 3) {
      s_finyear = s_finyear - 1;
      e_finyear = e_finyear;
    } else {
        s_finyear = s_finyear;
        e_finyear = e_finyear + 1;
    }
    this.fs_day = new Date(s_finyear, 3, 1);
    this.fe_day = new Date(e_finyear, 2, 31);
    var cs_day = new Date(new Date().getFullYear(), 0, 1);
    this.ranges = {
        'Today': [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Three Month': [moment().subtract(3, 'month'), moment()]
    };
    
    
      this.selectedDateRange= { startDate: moment().startOf('month'), endDate: moment().endOf('month') };
    
  }
  selectedDateRange: any;

  // ranges: any = {
  //   'Today': [moment(), moment()],
  //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  //   'This Month': [moment().startOf('month'), moment().endOf('month')],
  //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  // }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  
  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = m.weekday() === 0 || m.weekday() === 6;
    if (tooltip) {
      return 'Weekends not allowed!';
    } else {
      return false;
    }
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    localStorage.setItem('activelink', titlee.slice(1));
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }
}

import { Routes } from '@angular/router';
import { Page404Component } from './page404/page404.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'webhome',
    pathMatch: 'full',
  },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
   // otherwise redirect to home
   { path: '**', component: Page404Component }
]


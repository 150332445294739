import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WebHeaderComponent } from './webheader.component';

@NgModule({
    imports: [ RouterModule, CommonModule,FormsModule
     ],
    declarations: [ WebHeaderComponent ],
    exports: [ WebHeaderComponent ]
})

export class WebHeaderModule {}

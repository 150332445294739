import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetData } from '../../../shared/interfaces/assetdata.interface'; // Ensure the correct path
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

@Component({
  selector: 'app-applicant-profile', 
  templateUrl: './applicant-profile.component.html',
  styleUrl: './applicant-profile.component.css',
  providers:[DatePipe,ExcelService,PanchayatService]
})
export class ApplicantProfileComponent implements OnInit {
  profileForm!: FormGroup;
  currentDate: any="";
  lstLevel2:any=[];
  PanchayatID:any="Manikpur";
  selProfiletype: string="View";
  assetdetail:any;
  profiletype:any;
  lstprofiletype: any[] = [
    { name: 'View', value: 'View' },
    { name: 'Update', value: 'Update' },
    { name: 'Add New', value: 'Add' }
]; 
getLevel2s(): void {    
  this.lstLevel2 =this.panchayatservice.getLevel2s();
}
  constructor(private panchayatservice:PanchayatService,private fb: FormBuilder,public ref: DynamicDialogRef,public config: DynamicDialogConfig ,private datePipe: DatePipe) {}
  ngOnInit(): void {
    this.getLevel2s();
    this.currentDate = this.datePipe.transform(new Date(), 'dd MMM yyyy');
    this.assetdetail=this.config.data.assetdata;
    this.profiletype=this.config.data.profiletype;
    if(this.assetdetail==null){
      this.selProfiletype='Add';
      this.ResetFormData();
    }
    else{
      this.selProfiletype='View';
    }
    this.buildForm();
    
  }
  buildForm(){
    this.profileForm = this.fb.group({
      ward_id: [this.assetdetail?.ward_id, Validators.required],
      PropertyOwnerName: [this.assetdetail?.PropertyOwnerName, Validators.required],
      PropertyOwnerFatherName: [this.assetdetail?.PropertyOwnerFatherName, Validators.required],
      PropertyOwnerGender: [this.assetdetail?.PropertyOwnerGender, Validators.required],
      PropertyOwnerAge: [this.assetdetail?.PropertyOwnerAge, Validators.required],
      PropertyOwnerMobile: [this.assetdetail?.PropertyOwnerMobile, [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      PropertyOwnerAadhar: [this.assetdetail?.PropertyOwnerAadhar, [Validators.required, Validators.pattern('^[0-9]{12}$')]],
      PanchayatHouseNumber: [this.assetdetail?.PanchayatHouseNumber, [Validators.required]],
      ConstructionYear: [this.assetdetail?.ConstructionYear, [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      AnnualValue: [this.assetdetail?.AnnualValue, [Validators.required, Validators.pattern('^[0-9]{5}$')]],
      AnnualValueYear: [this.assetdetail?.AnnualValue, [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      WaterConnectionNumber: [this.assetdetail?.PanchayatHouseNumber, [Validators.required]],
      FamilyOwnerMobile: [this.assetdetail?.FamilyOwnerMobile, [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      AadharCardNo: [this.assetdetail?.AadharCardNo, [Validators.required, Validators.pattern('^[0-9]{12}$')]],
      FamilyOwnerName: [this.assetdetail?.FamilyOwnerName, Validators.required],
      FamilyOwnerFatherName: [this.assetdetail?.FamilyOwnerFatherName, Validators.required],
      FamilyOwnerAge: [this.assetdetail?.FamilyOwnerAge, Validators.required],
      AssetAddress: [this.assetdetail?.AssetAddress, Validators.required],
      Mohalla: [this.assetdetail?.Mohalla, Validators.required],
      Landmark: [this.assetdetail?.Landmark, Validators.required],                  
      FamilyType: [this.assetdetail?.FamilyType, Validators.required],
      FamilyTypeCardNo: [this.assetdetail?.FamilyTypeCardNo, Validators.required],      
      PanCardNo: [this.assetdetail?.PanCardNo, Validators.required],
      VoterCardo: [this.assetdetail?.VoterCardo, Validators.required],
      RashanCardNo: [this.assetdetail?.RashanCardNo, Validators.required],
      RashanCardNoOwnerName: [this.assetdetail?.RashanCardNoOwnerName, Validators.required],
      FamilyOwnerGender: [this.assetdetail?.FamilyOwnerGender, Validators.required],
      Category: [this.assetdetail?.Category, Validators.required],
      Religion: [this.assetdetail?.Religion, Validators.required],
      Caste: [this.assetdetail?.Caste, Validators.required],
      OwnershipType: [this.assetdetail?.OwnershipType, Validators.required],
      PlotRegisterType: [this.assetdetail?.PlotRegisterType, Validators.required],
      PlotType: [this.assetdetail?.PlotType, Validators.required],
      ConstructionType: [this.assetdetail?.ConstructionType, Validators.required],
      RoadType: [this.assetdetail?.RoadType, Validators.required],
      RoadLength: [this.assetdetail?.RoadLength, Validators.required],
      RoadCleaniness: [this.assetdetail?.RoadCleaniness, Validators.required],
      SummersablePumpStatus: [this.assetdetail?.SummersablePumpStatus, Validators.required],
      WaterSupplyType: [this.assetdetail?.WaterSupplyType, Validators.required],
      IsElectricitySupply: [this.assetdetail?.IsElectricitySupply, Validators.required],
      SewageType: [this.assetdetail?.SewageType, Validators.required],
      SewageCleaniness: [this.assetdetail?.SewageCleaniness, Validators.required],
      IsGasConnection: [this.assetdetail?.IsGasConnection, Validators.required],
      IsOwnerLiveHere: [this.assetdetail?.IsOwnerLiveHere, Validators.required],
      NoOfToilet: [this.assetdetail?.NoOfToilet, Validators.required],
      NoOfFloor: [this.assetdetail?.NoOfFloor, Validators.required],
      NoOfRoom: [this.assetdetail?.NoOfRoom, Validators.required],
      NoOfShop: [this.assetdetail?.NoOfShop, Validators.required],
      ToiletConstructAgency: [this.assetdetail?.ToiletConstructAgency, Validators.required],
      PlotArea: [this.assetdetail?.PlotArea, Validators.required],
      ConstructedArea: [this.assetdetail?.ConstructedArea, Validators.required],
      temporaryConstructArea: [this.assetdetail?.temporaryConstructArea, Validators.required],

      memberList: this.fb.array([])
    });
    this.assetdetail?.memberDetails.forEach((member:any) => {
      this.addMember(member);
    });    
  }
  ResetFormData(){
    if(this.profileForm==undefined)return;
      this.profileForm.get('PropertyOwnerName')?.reset('');
      this.profileForm.get('PropertyOwnerFatherName')?.reset('');
      this.profileForm.get('PropertyOwnerGender')?.reset('');
      this.profileForm.get('PropertyOwnerAge')?.reset('');
      this.profileForm.get('PropertyOwnerMobile')?.reset('');
      this.profileForm.get('PropertyOwnerAadhar')?.reset('');

      this.profileForm.get('FamilyOwnerMobile')?.reset('');
      this.profileForm.get('AadharCardNo')?.reset('');
      this.profileForm.get('FamilyOwnerName')?.reset('');
      this.profileForm.get('FamilyOwnerFatherName')?.reset('');
      this.profileForm.get('FamilyOwnerAge')?.reset('');
      this.profileForm.get('AssetAddress')?.reset('');
      this.profileForm.get('Mohalla')?.reset('');
      this.profileForm.get('Landmark')?.reset('');
      this.profileForm.get('FamilyType')?.reset('');
      this.profileForm.get('FamilyTypeCardNo')?.reset('');
      this.profileForm.get('PanCardNo')?.reset('');
      this.profileForm.get('VoterCardo')?.reset('');
      this.profileForm.get('RashanCardNo')?.reset('');
      this.profileForm.get('RashanCardNoOwnerName')?.reset('');
      this.profileForm.get('FamilyOwnerGender')?.reset('');
      this.profileForm.get('Category')?.reset('');
      this.profileForm.get('Religion')?.reset('');
      this.profileForm.get('Caste')?.reset('');
      this.profileForm.get('OwnershipType')?.reset('');
      this.profileForm.get('PlotRegisterType')?.reset('');
      this.profileForm.get('PlotType')?.reset('');
      this.profileForm.get('ConstructionType')?.reset('');
      this.profileForm.get('RoadType')?.reset('');
      this.profileForm.get('RoadLength')?.reset('');
      this.profileForm.get('RoadCleaniness')?.reset('');
      this.profileForm.get('SummersablePumpStatus')?.reset('');
      this.profileForm.get('WaterSupplyType')?.reset('');
      this.profileForm.get('IsElectricitySupply')?.reset('');
      this.profileForm.get('SewageType')?.reset('');
      this.profileForm.get('SewageCleaniness')?.reset('');
      this.profileForm.get('IsGasConnection')?.reset('');
      this.profileForm.get('IsOwnerLiveHere')?.reset('');
      this.profileForm.get('NoOfToilet')?.reset('');
      this.profileForm.get('NoOfFloor')?.reset('');
      this.profileForm.get('NoOfRoom')?.reset('');
      this.profileForm.get('NoOfShop')?.reset('');
      this.profileForm.get('ToiletConstructAgency')?.reset('');
      this.profileForm.get('PlotArea')?.reset('');
      this.profileForm.get('ConstructedArea')?.reset('');
      this.profileForm.get('temporaryConstructArea')?.reset('');
      memberList: this.fb.array([])
  }
  get memberList(): FormArray {
    return this.profileForm.get('memberList') as FormArray;
  }
  addMember(memberData?: { MemberName: string;MemberGaurdianName:string;MemberRelation:string; MemberAge: number;MemberGender:string; 
    MemberMobile: string;MemberAadharCardNo: string;MemberEducationStatus: string;MemberMarritalStatus: string;MemberIncome: string }): void {
    this.memberList.push(this.fb.group({
      MemberName: [memberData ? memberData.MemberName : ''],
      MemberGaurdianName: [memberData ? memberData.MemberGaurdianName : ''],
      MemberRelation: [memberData ? memberData.MemberRelation : ''],
      MemberAge: [memberData ? memberData.MemberAge : ''],
      MemberGender: [memberData ? memberData.MemberGender : ''],
      MemberMobile: [memberData ? memberData.MemberMobile : ''],
      MemberAadharCardNo: [memberData ? memberData.MemberAadharCardNo : ''],
      MemberEducationStatus: [memberData ? memberData.MemberEducationStatus : ''],
      MemberMarritalStatus: [memberData ? memberData.MemberMarritalStatus : ''],
      MemberIncome: [memberData ? memberData.MemberIncome : '']
    }));
  }
  saveMember(index: number): void {
    const member = this.memberList.at(index);
    console.log('Saved member:', member.value);
    // Implement the logic to save the member data, e.g., send it to a server
  }
  removeMember(index: number): void {
    this.memberList.removeAt(index);
  }
  SaveApplicantData(): void {
    console.log(this.profileForm.value);
    // Handle form submission, e.g., send updated data to a server
  }
  @ViewChild('PdfDiv') PdfDiv!: ElementRef;
  printDiv() {
    let filename ='Pariwar Register-'+this.config.data.assetdata.SurveyJsonID+'.pdf';
    const content = this.PdfDiv.nativeElement.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="http://smilingbrains.com/assets/css/style.css?v=2.0" rel="stylesheet" />');
    printWindow?.document.write(`<style>
    table {
      border-collapse:collapse;
    }
    
    table tr td {
      border: solid black 1px;
      padding: 5px 7px;
    }
    
    table tr th {
      border: none;
      padding-top: 5px;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }
}

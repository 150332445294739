<app-webslider></app-webslider>
<div class="marque">
    <div class="notice sticky">
        <marquee scrollamount="5" onmouseover="this.stop();" onmouseout="this.start();">
            <ul class="list-inline" style="width: 100000px">
                <li><a style="color:red;" href="#"></a></li>
            </ul>
        </marquee>
    </div>
</div>

<section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4"><button style="width:100%;border-radius:50px; border:2px solid black; color:black;" type="button" class="btn btn-warning"><i style="color:black;" class="fa fa-bookmark"></i>&nbsp; शिकायतें  </button></div>
            <div class="col-md-4"><button style="width:100%;border-radius:50px; border:2px solid black; color:black;" type="button" class="btn btn-warning"> <a style="color:black;" href="#/login"><i class="fa fa-user"></i>&nbsp; लॉगिन  </a></button></div>
            <div class="col-md-4"><button style="width:100%;border-radius:50px; border:2px solid black; color:black;" type="button" class="btn btn-warning"><i style="color:black;" class="fa fa-pencil-square-o"></i>&nbsp; शिकायत दर्ज करें   </button></div>
        </div>
    </div>
    <br /><br />
    <div class="container-fluid" id="demo">
        <div class="row">
            <div class="col-sm-3">
                <center id="para5" style="background-color: #0a782f !important;">
                    <img class="img9" src="assets/images/Manikpur/index.jpeg" width="100px" height="100px"><div style="margin-top: 13px; font-size: 15px;">
                        <strong>
                            <u style="color: white; ">
                                श्रीमती चंद्र लता जायसवाल
                            </u>
                        </strong><br><b style="color:white;">अध्यक्ष <br>नगर पंचायत परिषद मानिकपुर </b>
                    </div>
                </center>
            </div>
            <div class="col-sm-6" id="para2" style="background-color: #0a782f !important;">
                <p style="color:white;padding-top:25px;"> <i style="font-size:28px;">मानिकपुर (Manikpur)</i> या गढ़ी मानिकपुर (Garhi Manikpur) भारत के उत्तर प्रदेश राज्य के प्रतापगढ़ ज़िले में स्थित एक नगर है। यह गंगा नदी के किनारे बसा हुआ है। इस शहर को "राजाओं और संतों का शहर" कहा जाता है, मानिकपुर में रहने वाले राजाओं के नाम अलाउद्दीन खिलजी, जलालुद्दीन खिलजी, बलबन, राजा मानिकचंद, राजा तस्सुक हुसैन (अवध के नवाब वाजिद अली शाह के ससुर) हैं। मानिकपुर खोया (मावा) के लिए भी प्रसिद्ध है। शहर की स्थापना को लेकर बड़ा विवाद है। कहा जाता है कि यह स्थान [किसके अनुसार?] 1638 में स्थापित किया गया था। शहर में अभी भी कुछ वास्तुशिल्प अवशेष हैं जो इतिहास बताते हैं और इसके गौरवशाली अतीत को दर्शाते हैं। मानिकपुर टाउन उत्तर प्रदेश का दूसरा सबसे पुराना शहर है| 
                    <a href="https://en.wikipedia.org/wiki/Manikpur,_Pratapgarh" target="_blank"><b style="color:#ff7c1b; font-size:20px;">.... और देखें</b></a></p>
            </div>
            <div class="col-sm-3">
                <center id="para2" style="background-color: #0a782f !important;"><img class="img1" src="assets/images/Manikpur/index1.jpeg" width="100px" height="100px"><div style="margin-top: 13px; font-size: 15px;">
                    <strong><u style="color: white; ">श्री. अजीत कुमार सिंह </u></strong><br><b style="color:white;"> अधिशासी अधिकारी <br>नगर पंचायत परिषद मानिकपुर </b></div></center>
            </div>
        </div>
    </div><br />

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="ward">
                    <h5>Ward List</h5>
                    <table width="95%">
                        <tr>
                            <td>1</td>
                            <td>
                        चौरही</td>
                        <td>

वीरेन्द्र कुमार</td></tr>
<tr>
<td>

2</td>
<td>

बजहाभीट</td>
<td>

चंदा</td></tr>
<tr>
<td>

3</td>
<td>

भोला का पुरवा</td>
<td>

अर्जुन कुमार</td></tr>
<tr>
<td>

4</td>
<td>

मिरगडवा</td>
<td>

शोभा</td></tr>
<tr>
<td>

5</td>
<td>

अदलपुर</td>
<td>

कृष्णा</td></tr>
<tr>
<td>

6</td>
<td>

हुलास राय का पुरवा</td>
<td>

पन्ना लाल सोनकर</td></tr>
<tr>
<td>

7</td>
<td>

लाटतारा</td>
<td>

जगनाथ</td></tr>
<tr>
<td>

8</td>
<td>

रानापट्टी</td>
<td>

धर्मचन्द्र</td></tr>
<tr>
    <td>9</td>
<td>

बभनपुर</td>
<td>

ललित जायसवाल</td></tr>
<tr>
<td>

10</td>
<td>

चौकापारपुर</td>
<td>

रमेश सिंह</td></tr>
<tr>
<td>

11</td>
<td>

मनिहारी टोला</td>
<td>

दिलीप कुमार निषाद</td></tr>
<tr>
<td>

12</td>
<td>

खानकाह</td><td>

    रूकसाना बानो</td></tr>
<tr>
<td>

13</td>
<td>

काछीपटटी</td>

<td>

रमेश मौर्या</td></tr>
<tr>
    <td>14</td>
<td>

सुल्तानपुर</td>
<td>

मो० साहिद</td></tr>
<tr>
<td>

15</td>
<td>

मां ज्वाला देवी</td>
<td>

मो० अनस</td></tr>
<tr>
<td>

16</td>
<td>

मानिकपुर</td>
<td>

विनोद शंकर</td></tr>
<tr>
<td>

17</td>
<td>

शाहाबाद</td>
<td>

कुसुमा देवी</td></tr>
                            <td>
                    </table>
                    <br/><br/>
                </div>
            </div>
            <div class="col-md-9"><div class="ward">
                <h5>Map</h5>
                <!--Google map-->
                <div id="map-container-google-4" class="z-depth-1-half map-container-4" style="height: 500px">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27620.67462543031!2d81.37578037126532!3d25.77276360004399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399b22150fa344c1%3A0xe943a1cd7e2e1273!2sManikpur%2C%20Uttar%20Pradesh!5e1!3m2!1sen!2sin!4v1705205697447!5m2!1sen!2sin" 
                    style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                             
                </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="services-container">
    <h1 class="h-secondary center">हमारा लक्ष्य</h1>
    <div id="services">
        <div class="col-md-4">
            <div class="w3-card">
                <p> <img src="assets/images/Manikpur/SmartCity.png" class="img-responsive margin" style="width:100%" alt="Image"> प्रधानमंत्री नरेन्द्र मोदी के नेतृत्व वाली राष्ट्रीय जनतांत्रिक गठबंधन सरकार ने शहरी भारत को रहन-सहन, परिवहन और अन्य अत्याधुनिक सुविधाओं से लैस करने के इरादे से स्मार्ट सिटीज जैसी योजनाएं बनाई हैं|<a href="https://smartcities.gov.in/" target="_blank"><b style="color:red; font-size:20px;">.... और देखें</b></a></p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="w3-card">
                <p> <img src="assets/images/Manikpur/hamaralakshya2.jpg" class="img-responsive margin" style="width:100%" alt="Image"> प्रधानमंत्री नरेन्द्र मोदी के नेतृत्व वाली राष्ट्रीय जनतांत्रिक गठबंधन सरकार ने शहरी भारत को रहन-सहन, परिवहन और अन्य अत्याधुनिक सुविधाओं से लैस करने के इरादे से स्मार्ट सिटीज जैसी योजनाएं बनाई हैं|<a href="https://smartcities.gov.in/" target="_blank"><b style="color:red; font-size:20px;">.... और देखें</b></a></p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="w3-card">
                <p> <img src="assets/images/Manikpur/hamaralakshya3.png" class="img-responsive margin" style="width:100%" alt="Image"> प्रधानमंत्री नरेन्द्र मोदी के नेतृत्व वाली राष्ट्रीय जनतांत्रिक गठबंधन सरकार ने शहरी भारत को रहन-सहन, परिवहन और अन्य अत्याधुनिक सुविधाओं से लैस करने के इरादे से स्मार्ट सिटीज जैसी योजनाएं बनाई हैं|<a href="https://smartcities.gov.in/" target="_blank"><b style="color:red; font-size:20px;">.... और देखें</b></a></p>
            </div>
        </div>
    </div><br/>
</section>
<section id="client-section">
    <div class="row">
        <div class="col-md-2">
            <a href="https://e-nagarsewaup.gov.in/ulbappsmain/home" target="_blank"><img src="assets/images/Manikpur/group4.jpeg" width="200" height="80" alt="Natural" /></a>
        </div>
        <div class="col-md-2">
            <a href="https://gem.gov.in/" target="_blank"><img src="assets/images/Manikpur/group1.jpg" width="200" height="80" alt="Natural" /></a>
        </div>
        
        <div class="col-md-2">
            <a href="https://www.india.gov.in/" target="_blank"><img src="assets/images/Manikpur/group3.png" width="200" height="80" alt="Natural" /></a>
        </div>
        <div class="col-md-2">
            <a href="https://www.mygov.in/" target="_blank"><img src="assets/images/Manikpur/group.png" width="200" height="80" alt="Natural" /></a>
            
        </div>
        <div class="col-md-2"><a href="https://digitalindia.gov.in/" target="_blank"><img src="assets/images/Manikpur/group5.jpg" width="200" height="80" alt="Natural" /></a></div><div class="col-md-2">
            <a href="https://up.gov.in/" target="_blank"><img src="assets/images/Manikpur/group2.jpg" width="200" height="80" alt="Natural" /></a>
        </div>
    </div>
   
</section>
<h2 class="innerh2">गैलरी </h2><br />

<section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">


    <div class="container" style="margin-top: 20px;">
        <div class="row">
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery.jpg" alt="Lights" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery1.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery1.jpg" alt="Nature" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery2.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery2.jpg" alt="Fjords" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery3.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery3.jpg" alt="Fjords" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery4.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery4.jpg" alt="Fjords" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery5.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery5.jpg" alt="Fjords" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery6.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery6.jpg" alt="Lights" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery7.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery7.jpg" alt="Lights" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="thumbnail" style="height: 210px; width: 300px;">
                    <a href="assets/images/Manikpur/manikpurgallery8.jpg" target="_blank">
                        <img src="assets/images/Manikpur/manikpurgallery8.jpg" alt="Lights" style="height: 200px; width: 300px;">
                        <div class="caption">
                            <p></p>
                        </div>
                    </a>
                </div>
            </div>
            
        </div>
    </div>

</section>

<div class="row">
    <div class="col-md-3">
        <div class="heading">
            <h4>Taxation<small></small></h4>
        </div>
    </div>
    <div class="col-md-9">
        <div class="m-srp-card__action">
            <div class="m-srp-card__action__item">
                <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="openProfileModal(null, 'AddWaterCharge')">
                    Add New Water Connection
                </a>                        
            </div>
        </div>                        
    </div>
</div>
<div class="row">
  <div class="col-md-12">        
      <table class="table table-bordered table-striped table-condensed" style="font-size: small; font-weight: bold;">
          <thead>
              <tr>
              <th>क्रम संख्या</th>
              <th>यूनिक ID</th>
              <th>सम्पत्ति के मालिक का नाम</th>
              <th>सम्पत्ति के मालिक के पिता / पति का नाम</th>                
              <th>मोहल्ला</th>
              <th>सम्पत्ति के मालिक का फ़ोन नंबर</th>
              <th>सम्पत्ति श्रेणी</th>
              <th>पानी की आपूर्ति</th>
              <th>सीवेज का प्रकार</th>
              <th>बिजली का मीटर</th>
              <th>शौचालय</th>
              <th>प्लाट क्षेत्रफल</th>                
              <th>अस्थायी निर्माण क्षेत्र</th>
              </tr>
          </thead>
          <tbody>
              <ng-container *ngFor="let asset of assetList">
                  <tr (click)="toggleExpand(asset.id)">
                      <td>{{asset.SNo}}</td>
                      <td>{{asset.PropertyID}}</td>
                      <td>{{asset.PropertyOwnerName}}</td>
                      <td>{{asset.PropertyOwnerFatherName}}</td>
                      <td>{{asset.Mohalla}}</td>
                      <td>{{asset.PropertyOwnerMobile}}</td>
                      <td>{{asset.PlotType}}</td>                    
                      <td>{{asset.WaterSupplyType}}</td>
                      <td>{{asset.SewageType}}</td>
                      <td>{{asset.IsElectricitySupply}}</td>
                      <td>{{asset.ToiletConstructAgency}}</td>
                      <td>{{asset.PlotArea}}</td>
                      <td>{{asset.temporaryConstructArea}}</td>
                  </tr> 
                  <tr *ngIf="expandedMap[asset.id]">
                      <td colspan="13">
                          <div class="m-srp-card__collapse js-collapse">
                              <div class="m-srp-card__summary js-collapse__content">
                                  <div class="m-srp-card__summary__item">
                                      <div class="m-srp-card__summary__title">
                                          plot type
                                      </div>
                                      <div class="m-srp-card__summary__info">{{asset.PlotType}}</div>
                                  </div>                                   
                                  <div class="m-srp-card__summary__item">
                                      <div class="m-srp-card__summary__title">Plot RegisterType</div>
                                      <div class="m-srp-card__summary__info">{{asset.PlotRegisterType}}</div>
                                  </div>
                                  <div class="m-srp-card__summary__item">
                                      <div class="m-srp-card__summary__title">Plot Area</div>
                                      <div class="m-srp-card__summary__info">{{asset.PlotArea}}</div>
                                  </div>
                                  <div class="m-srp-card__summary__item">
                                      <div class="m-srp-card__summary__title">Construction Type</div>
                                      <div class="m-srp-card__summary__info">{{asset.ConstructionType}}</div>
                                  </div>                                    
                                  <div class="m-srp-card__summary__item">
                                      <div class="m-srp-card__summary__title">Road</div>
                                      <div class="m-srp-card__summary__info">{{asset.RoadLength}}-{{asset.RoadType}}</div>
                                  </div>                                                                        
                              </div>                                
                          </div>
                          <div class="m-srp-card__action">
                              <div class="m-srp-card__action__item">
                                  <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="openProfileModal(asset, 'HouseTax')">
                                      House Tax
                                  </a>
                                  <div class="contactForms">
                                      <div class="contactForm contactAgentForm formsWrap"></div>
                                  </div>
                              </div>
                              <div class="m-srp-card__action__item" *ngIf="asset.WaterSupplyType=='नगर पंचायत / पालिका'">
                                  <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="openProfileModal(asset, 'WaterTax')">
                                      Water Charge
                                  </a>
                                  <div class="contactForms">
                                      <div class="contactForm contactAgentForm formsWrap"></div>
                                  </div>
                              </div>
                              <div class="m-srp-card__action__item">
                                <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="openProfileModal(asset, 'Mutation')">
                                    Mutation
                                </a>
                                <div class="contactForms">
                                    <div class="contactForm contactAgentForm formsWrap"></div>
                                </div>
                            </div>
                            <div class="m-srp-card__action__item">
                                <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="openProfileModal(asset, 'BuildingApproval')">
                                    Building Approval
                                </a>
                                <div class="contactForms">
                                    <div class="contactForm contactAgentForm formsWrap"></div>
                                </div>
                            </div>
                          </div>
                      </td>
                  </tr>
              </ng-container>              
          </tbody>
      </table>
  </div>
  <div class="col-md-12">        
      <table class="table table-bordered table-striped table-condensed">
          <tbody>
              <tr>
                  <td style="vertical-align: middle;">
                      Page No. {{currentPage}}
                      <span class="form-group">                  
                      View 
                      <select [(ngModel)]="recordSize" (ngModelChange)="setRecordPerPage(recordSize)">
                          <option *ngFor="let val of recordPerPageValues" [value]="val">{{val}}</option>
                      </select>
                      records at a time.
                      </span>
                  </td>
                  <td>
                      <app-pager [totalItems]="totalCount" [itemperpage]="recordSize" [(currentPage)]="currentPage" (pageChange)="onPageChanged()"></app-pager>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
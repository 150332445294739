<!-- <nav class="navbar navbar-expand-md navbar-light shadow-sm">
    <div class="container-fluid">      
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" style="flex-direction: row-reverse !important;">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li>
                    <a href="#/admin/pariwar" class="text-uppercase">Pariwar Register</a>                                    
                </li>
                <li>
                    <a href="#/admin/taxation" class="text-uppercase">Taxation </a>                                    
                </li>
                <li>
                    <a href="#/admin/grievance" class="text-uppercase">Grievance </a>                                    
                </li> 
                <li>
                    <a href="#/admin/buildingapproval" class="text-uppercase">Building Approval </a>                                    
                </li>
                <li>
                    <a href="#/admin/tradelicense" class="text-uppercase">Trade License </a>                                    
                </li>
                <li>
                    <a href="#/admin/assetlease" class="text-uppercase">Asset Lease </a>                                    
                </li>
                                               
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                   Reports
              </a>
              <ul class="dropdown-menu ms-auto mb-2 mb-lg-0" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" href="#/admin/reporthousetax" class="text-uppercase">
                      <i class="icon-graph"></i> Pending House Tax </a>                                    
              </li>
                <li>
                  <a class="dropdown-item" href="#/admin/reportwatertax" class="text-uppercase">
                      <i class="icon-graph"></i> Pending Water Tax </a>                                    
              </li>   

              </ul>
          </li>
          <li  ng-class="PageName == 'activity_calendar.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
            <a class="dropdown-item" (click)="logout()">Logout</a>                                  
        </li>               
          </ul>
      </div>
  </div>
</nav> -->

<nav class="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-fullwidth" data-xl-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-device-layout="rd-navbar-static" data-md-stick-up-offset="130px" data-lg-stick-up-offset="100px" data-stick-up="true" data-sm-stick-up="true" data-md-stick-up="true" data-lg-stick-up="true" data-xl-stick-up="true">
    <!-- <div class="rd-navbar-collapse-toggle" data-rd-navbar-toggle=".rd-navbar-collapse"><span></span></div>-->
    <div class="rd-navbar-inner">        
        <div class="rd-navbar-aside-center">
            <div class="rd-navbar-nav-wrap" id="cssmenu">
                <!-- RD Navbar Nav-->
                <ul class="rd-navbar-nav btn-group-justified-me" style="text-transform: uppercase !important;">
                    <li><a href="#/admin/pariwar">&nbsp;Pariwar Register</a></li>
                    <li><a href="#/admin/taxation">&nbsp;Taxation</a></li>
                    <li><a href="#/admin/grievance">&nbsp;Grievance</a></li>
                    <li><a href="#/admin/buildingapproval">&nbsp;Building Approval</a></li>
                    <li><a href="#/admin/tradelicense">&nbsp;Trade License</a></li>
                    <li><a href="#/admin/assetlease">&nbsp;Asset Lease</a></li>
                    <li class="has-sub">
                        <span class="submenu-button"></span>
                        <a href="javascript:;" data-hover="megamenu-dropdown" data-close-others="true">
                            &nbsp;Reports&nbsp;<i class="fa fa-bar-chart"></i>
                        </a>
                        <ul class="">
                            <li>
                                <a href="#/admin/reporthousetax">Pending House Tax </a>
                            </li>
                            <li>
                                <a href="#/admin/reportwatertax">Pending Water Tax </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a (click)="logout()"><i class="fa fa-sign-out"></i>&nbsp;Logout</a>                                                    
                    </li>                    
                </ul>
            </div>
        </div>
    </div>
</nav>
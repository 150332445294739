import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TaxationListComponent } from '../taxation-list/taxation-list.component';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css'],
  providers: [DialogService, MessageService]
})
export class StatsComponent implements OnInit {
  @Input() datafor: string = '';
  @Input() valuefor: string = '';
  @Input() valueforid: string = '';
  @Input() title: string = '';
  @Input() activevalue:number =-1;
  @Input() value: number = 0;
  @Input() change:number = 0;
  @Input() isdataloaded:boolean=false;
  @Input() loadmore:boolean=true;
  constructor(public dialogService: DialogService, public messageService: MessageService) { }

  ngOnInit(): void {
  }
  ref: DynamicDialogRef | undefined;

  show() {
      this.ref = this.dialogService.open(TaxationListComponent, { 
         data: {
            modalfor: this.datafor,
            valuefor:this.valuefor,
            valueforid:this.valueforid,
            isMaster:true
          },
          header: this.title,
          width: '99%',
          height:'99vh',
          contentStyle: { overflow: 'auto' },
          baseZIndex: 10000,
          maximizable: true,
          draggable:true,
          resizable:false
      });

      this.ref.onClose.subscribe((product: any) => {
          if (product) {
              this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
          }
      });

      this.ref.onMaximize.subscribe((value) => {
          this.messageService.add({ severity: 'info', summary: 'Maximized', detail: `maximized: ${value.maximized}` });
      });
  }

  ngOnDestroy() {
      if (this.ref) {
          this.ref.close();
      }
  }
}

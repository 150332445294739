<h2 class="innerh2">लॉगिन करें </h2>
    <section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">
        <div class="container container-bigger form-request-wrap form-request-wrap-modern">
            <div class="row">
                <div class="col-lg-12 col-xxl-12">
                    <div class="login-form">
                        <div class="avatar">
                            <img src="assets/images/icon-login.png" />
                        </div>                        
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">                            
                            <div class="mb-3">
                                <label class="form-label">Username</label><br>
                                <input type="text" formControlName="username" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Username is required</div>
                                </div>                  
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Password</label><br>
                                <input type="password" formControlName="password" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                            <div>
                                <button [disabled]="loading" class="btn btn-primary">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                                    Login
                                </button>
                            </div>
                            <div class="form-group">
                              <div *ngIf="f.submitted && isLoginFailed" class="alert alert-danger" role="alert">
                                Login failed: {{ errorMessage }}
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
import { Component, OnInit } from '@angular/core';  
import { Router } from '@angular/router';  
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/shared/services/excel.service';
interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

@Component({
  selector: 'app-webhome',
  templateUrl: './webhome.component.html',
  styleUrls: ['./webhome.component.css'],
  providers:[ExcelService]
})
export class WebHomeComponent implements OnInit {
  dataResult:any=[];
  constructor(private excelService: ExcelService,private router: Router,public http: HttpClient) {
    this.excelService = excelService;
  } 
  ngOnInit() {  
    this.getPDF();
  } 
  exportToExcel(event:any) {
    this.excelService.exportAsExcelFile(this.dataResult, 'persons');
  }
  /* GET API Calling */
  getApi(): Observable<any | null> {
    let url = 'https://jsonplaceholder.typicode.com/todos/'
    return this.http.get(url).pipe(map((res: any) => {
      let resData = res;
      return resData;
    }));
  }
	getPDF() {
		this.getApi().subscribe((getListData: any) => {
			this.dataResult = getListData;
      console.log(this.dataResult)
		},
			(error: any) => {
				//	this.noData = true;
			}
		);
	}
  generatePdf() {
    const doc = new jsPDF() as jsPDFCustom;
    let finalY = 0;
    doc.autoTable({
      html: '#imgTable',
      bodyStyles: { minCellHeight: 8 },
      theme: 'grid',
      styles: { valign: 'middle', overflow: 'linebreak', halign: 'center', minCellHeight: 5 },
      pageBreak: 'avoid',
    /*  columnStyles: {
        0: { cellWidth: 20, minCellHeight: 5}
      },
      */
      headStyles: { fillColor: '#f2f2f2', textColor: '#000', fontStyle: 'bold', lineWidth: 0.5, lineColor: '#ccc' },
      didParseCell(data) {
        if (data.row.index === 0) {
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = '#379AAE';
        }
        doc.setFontSize(10).setFont('undefined', 'bold');
        doc.text(
          "USER DETAILS AND HISTORY",
          85,
          8
        );
      },
    });
    doc.deletePage(1)
    doc.save('user_details.pdf');
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-report-house-tax',  
  templateUrl: './report-house-tax.component.html',
  styleUrls: ['./report-house-tax.component.css']
})
export class ReportHouseTaxComponent  implements OnInit{
  rptHousetax:any[]=[];
  filterState: any = {};
  private filterStateSubscription!: Subscription;
  constructor(private shareService:ShareService,private panchayatservice: PanchayatService,private filterService: FilterService
    ) {    

  }
  ngOnInit(): void {    
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
     // Update filter state with the changed value
     this.filterState = filterData.filterState; 
     // Call the method in other component to handle the filter changes
     if(this.filterService.isInitialized()){
     //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.getReportHouseTaxData();
     }
    });                 
  }
  getReportHouseTaxData(): void {
    this.shareService.setParam(true);
    this.rptHousetax = [];
    this.panchayatservice.getAssetData("ReportHouseTax",this.filterState,false,1,100).subscribe((data: any) => {
      if(data.length>0){        
        this.rptHousetax = JSON.parse(data).ResponseData;        
      }           
      this.shareService.setParam(false);
    });    
  }
}

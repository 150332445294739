<div class="row">
    <div class="col-md-12">
        <div class="heading">
            <h4>Building Approval<small></small></h4>
        </div>
    </div>
    <div class="col-md-12">
        <div id="no-more-tables">
            <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small;font-weight:bold">
                <thead class="cf">
                    <tr>
                        <th>Action</th>
                        <th>S.No.</th>
                        <th>Ward</th>
                        <th>Mobile No.</th>
                        <th>Aadhar Card</th>
                        <th>Applicant Name</th>
                        <th>Father/Husband Name</th>
                        <th>Address</th>
                        <th>Max.Building Height</th>
                        <th>Plot Number</th>
                        <th>Plot Area</th>
                        <th>Constructed Area</th>                        
                        <th>Proposed Constructed Area</th>
                        <th>Nature of Construction</th>
                        <th>Architect Name</th>
                        <th>Property Cost</th>
                        <th>Approval Fee</th>                    
                        <th>Entered On</th>
                        <th>Approval Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let bapprov of buildingapproval; let i = index">
                        <tr>
                            <td><a (click)="selectrow(bapprov)">Select</a></td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{i + 1}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.ward}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.MobileNumber}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.AadharCardNumber}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.ApplicantName}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.FathersHusbandsName}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.CorrespondenceAddress}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.MaxBuildingHeight}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.PlotNumber}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.PlotArea}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.ConstructedArea}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.ProposedConstructedArea}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.NatureOfConstruction}}</td>                            
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.ArchitectName}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.PropertyCost}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.BuildingApprovalFee}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.enteredon}}</td>
                            <td (click)="toggleExpand(bapprov.BuildingApprovalid)">{{bapprov.ApprovalStatus}}</td>
                        </tr>   
                        <tr *ngIf="expandedMap[bapprov.BuildingApprovalid]" class="fold open">
                            <td colspan="19" class="m-srp-card">
                                <div class="row">
                                    <div class="col-md-12" style="text-align:right">
                                        <button class="btn btn-warning" (click)="printDiv('dvhousetaxprint')">Print</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" id="dvhousetaxprint">
                                        <div class="row">
                                            <div class="col-md-12" id="dvhousetaxprint">
                                                <table style="font-size: small;font-weight:bold;width:600px;border:1px solid black;">
                                                    <tr>
                                                        <td colspan="4" style="text-align:center;">
                                                            <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG" style="width:600px;">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" style="text-align:center;">
                                                            <strong>भवन निर्माण स्वीकृति रशीद </strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"><qrcode
                                                            qrdata="{{bapprov.ApplicantName}} | {{bapprov.CorrespondenceAddress}} | {{bapprov.PlotNumber}} | {{bapprov.PlotArea}} | {{bapprov.NatureOfConstruction}} | {{bapprov.BuildingApprovalFee}}"
                                                            [allowEmptyString]="true"
                                                            [cssClass]="'center'"
                                                            [colorDark]="'#000000ff'"
                                                            [colorLight]="'#ffffffff'"
                                                            [elementType]="'img'"
                                                            [errorCorrectionLevel]="'M'"
                                                            [scale]="1"
                                                            [width]="80"
                                                            ></qrcode></td>
                                                        <td style="text-align:right;" colspan="2">
                                                            <strong>Reference Number :</strong>BA2024-{{bapprov.BuildingApprovalid}}<br/>                                                                            
                                                            <strong>Date:</strong>{{bapprov.enteredon}}<br/>                                                                            
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:25%">
                                                            <b>Name of Applicant</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{bapprov.ApplicantName}}
                                                        </td>
                                                        <td style="width:25%">
                                                            <b>Father/Husband Name</b>
                                                        </td>
                                                        <td style="text-align:left;width:25%">
                                                            {{bapprov.FathersHusbandsName}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Premisis Address:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{bapprov.CorrespondenceAddress}}
                                                        </td>
                                                        <td>
                                                            <b>Ward:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{bapprov.ward}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Mobile:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{bapprov.MobileNumber}}
                                                        </td>
                                                        <td>
                                                            <b>Aadhar Number:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{bapprov.AadharCardNumber}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                          <b>Max.Building Height:</b>
                                                      </td>
                                                      <td style="text-align:left;">
                                                          {{bapprov.MaxBuildingHeight}}
                                                      </td>
                                                      <td>
                                                          <b>Plot Number:</b>
                                                      </td>
                                                      <td style="text-align:left;">
                                                          {{bapprov.PlotNumber}}
                                                      </td>
                                                    </tr>
                                                      <tr>
                                                          <td>
                                                              <b>Plot Area:</b>
                                                          </td>
                                                          <td style="text-align:left;">
                                                              {{bapprov.PlotArea}}
                                                          </td>
                                                          <td>
                                                              <b>Constructed Area:</b>
                                                          </td>
                                                          <td  style="text-align:left;">{{bapprov.ConstructedArea}}</td>
                                                      </tr>
                                                      <tr>
                                                        
                                                        <td>
                                                            <b>Proposed Constructed Area:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{bapprov.ProposedConstructedArea}}</td>
                                                        <td>
                                                            <b>Nature Of Construction:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{bapprov.NatureOfConstruction}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td>
                                                            <b>Property Cost:</b>
                                                        </td>
                                                        <td style="text-align:left;">
                                                            {{bapprov.PropertyCost}}
                                                        </td>
                                                        <td>
                                                            <b>Building Approval Fee:</b>
                                                        </td>
                                                        <td  style="text-align:left;">{{bapprov.BuildingApprovalFee}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b>Architect Name:</b>
                                                        </td>
                                                        <td style="text-align:left;" colspan="3">
                                                            {{bapprov.ArchitectName}}
                                                        </td>
                                                    </tr>
                                                      <tr>
                                                        <td colspan="4" align="right">
                                                            <br /><br /><br />
                                                            <b>Executive Officer/ Secretary</b>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>                      
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-md-12"> 
        <form [formGroup]="constructionForm" (ngSubmit)="onSubmit()">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="font-red">
                    <!-- Update Data ID : {{selectsForm.controls['UpdateID'].value}} -->
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="mobileNumber">Ward:</label>
                    <select class='form-control' class='form-control' formControlName="ward_id">
                        <option value="0">Select Ward</option>
                        <option value="{{row.Level2_id}}" *ngFor="let row of lstLevel2">{{row.Level2_Name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="mobileNumber">Mobile Number:</label>
                    <input id="mobileNumber" class='form-control' formControlName="mobileNumber" type="text"  class='form-control'>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="aadharCardNumber">Aadhar Card Number:</label>
                    <input id="aadharCardNumber" formControlName="aadharCardNumber" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="applicantName">Applicant Name:</label>
                    <input id="applicantName" formControlName="applicantName" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="fathersHusbandsName">Father's/Husband's Name:</label>
                    <input id="fathersHusbandsName" formControlName="fathersHusbandsName" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="correspondenceAddress">Correspondence Address:</label>
                    <input id="correspondenceAddress" formControlName="correspondenceAddress" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="plotNumber">Plot Number:</label>
                    <input id="plotNumber" formControlName="plotNumber" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="maxBuildingHeight">Max Building Height:</label>
                    <input id="maxBuildingHeight" formControlName="maxBuildingHeight" type="text"  class='form-control' >
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="plotArea">Plot Area:</label>
                    <input id="plotArea" formControlName="plotArea" type="text"  class='form-control'>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="constructedArea">Constructed Area:</label>
                    <input id="constructedArea" formControlName="constructedArea" type="text"  class='form-control'>
                </div>
            </div>                                    
            <div class="col-md-6">
                <div class="form-group">
                    <label for="proposedConstructedArea">Proposed Constructed Area:</label>
                    <input id="proposedConstructedArea" formControlName="proposedConstructedArea" type="text"  class='form-control'>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="natureOfConstruction">Nature of Construction:</label>
                    <input id="natureOfConstruction" formControlName="natureOfConstruction" type="text"  class='form-control'>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="PropertyCost">Property Cost:</label>
                    <input id="PropertyCost" formControlName="PropertyCost" type="text"  class='form-control'>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="buildingApprovalFee">Building Approval Fee:</label>
                    <input id="buildingApprovalFee" formControlName="buildingApprovalFee" type="text"  class='form-control'>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="form-group">
                    <label for="architectName">Architect Name:</label>
                    <input id="architectName" formControlName="architectName" type="text"  class='form-control'>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="architectName">Status:</label>
                    <select class="form-control" formControlName="buildingapproval_status">
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label for="architectDocument">Uploaded Documents:</label>
                    <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small; font-weight: bold;">
                        <thead class="cf">
                            <tr>
                                <th>S.No.</th>
                                <th>Document Type</th>
                                <th>Document Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let doc of selUploadedDocuments;let ind=index;">
                                <td>{{ind+1}}</td>
                                <td>{{doc.imageCaption}}</td>
                                <td>{{doc.imageName}}</td>
                                <td><a [href]="doc.firebaseDownloadUrl" target="_blank">View</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label for="architectDocument">Documents:</label>
                    <app-file-upload [accept]="'image/*'"  [maxFiles]="4" [captionhidestatus]="true" [datehidestatus]="false" [maxSizePerFile]="1048576" [selectedFiles]="buildapprovalFile"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
                </div>
            </div>
            <div class="col-md-12">
                <button type="submit" class="m-srp-card__btn m-srp-card__btn--primary-o" [disabled]="constructionForm.invalid">Submit</button>
            </div>
        </form>
    </div>
    
</div>
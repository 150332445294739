<h2 class="innerh2">संपर्क सूत्र </h2><br />

<section id="skipContent" class="section section-lg text-center bg-gray-lighter novi-background bg-cover">


    <div class="container" style="margin-top: 20px;">
        <div class="row">

            <div class="col-md-6">
                <div class="card">
                    <center><a href="assets/images/Manikpur/index.jpeg" target="_blank"><img class="card-img-top" src="assets/images/Manikpur/index.jpeg" alt="Card image cap"></a></center>
                    <div class="card-body">
                        <h2 style="background-color:#0a782f; color:white; font-size:30px; padding:10px; text-shadow:2px 2px 8px black;"><strong id="strong">श्रीमती चंद्र लता जायसवाल</strong><br /><b style="font-size:25px;">( अध्यक्ष )</b><br /><i style="font-size:30px; padding:10px; color:orange;" class="fa fa-phone"></i>  </h2>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                    <center><a href="assets/images/Manikpur/index1.jpeg" target="_blank"><img class="card-img-top" src="assets/images/Manikpur/index1.jpeg" alt="Card image cap"></a></center>
                    <div class="card-body">
                        <h2 style="background-color:#0a782f; color:white; font-size:30px; padding:10px; text-shadow:2px 2px 8px black;"><strong id="strong">श्री. अजीत कुमार सिंह</strong><br /><b style="font-size:25px; text-shadow:2px 2px 8px black;">( अधिशासी अधिकारी )</b><br /><i style="font-size:30px; padding:10px; color:orange;" class="fa fa-phone"></i>  </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container">
        <form class="form-horizontal" style="text-align: center; border: 1px solid black; padding: 20px; margin-left: 200px; margin-right: 200px; border-radius: 50px; background-image: url(zz.jpg);">
            <div class="form-group">
                <div class="col-sm-12">
                    <i class="fa fa-address-card" style="font-size: 30px; color: orange;"></i>
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-12">
                    <i class="fa fa-phone" style="font-size: 30px; color: cornflowerblue;"></i>
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-12">
                    <i class="fa fa-envelope" style="font-size: 30px; color: green;"></i>
                </div>
            </div>


        </form><br>
    </div> -->

</section>

<!--
       ========================================================
                                   FOOTER
       ========================================================
       -->
       <footer>
   
        <section>
            <div class="container-fluid" style="text-align: center;margin-top: 10px;">
    
                <p style="background-color:black; color:white;">
                    इस वेब साइट का कंटेंट नगर पंचायत, मानिकपुर , जनपद - प्रतापगढ़ , उत्तर प्रदेश द्वारा प्रकाशित एवं संचालित किया जाता है।<br />
                    इस वेबसाइट के बारे में किसी भी प्रश्न के लिए, वेब सूचना प्रबंधक से संपर्क करें।
                </p>
    
                    <div class="copyright">
                        © <span id="copyright-year">2024</span>.&nbsp;&nbsp;<a href="#">नगर पंचायत - मानिकपुर</a>&nbsp;&nbsp;&nbsp;<b>|</b>&nbsp;&nbsp;&nbsp;<!-- {%FOOTER_LINK} -->
                    </div>
            </div>
        </section>
    </footer>
    
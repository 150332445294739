import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Page404Component } from './page404/page404.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './login/jwt.interceptor';
import { FilterService } from './shared/services/filter.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrintService } from './shared/services/print.service';
import { HomeLayoutModule } from './modules/home/home.module';
import { AdminModule } from './modules/admin/admin.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from './shared/shared.module';
import { SharedDialogOverlayComponent } from './shared/components/shared-dialog-overlay/shared-dialog-overlay.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,    
    Page404Component,    
    SharedDialogOverlayComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    CommonModule,
    SharedModule,
    HomeLayoutModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    AdminModule,

  ],
  providers: [FilterService,PrintService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

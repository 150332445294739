import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webslider',
  templateUrl: './webslider.component.html',
  styleUrls: ['./webslider.component.css']
})
export class WebSliderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  } 
}
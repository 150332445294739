import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-mutation',
  templateUrl: './mutation.component.html',
  styleUrl: './mutation.component.css'
})
export class MutationComponent {
  PanchayatID:any="Manikpur";
  mutationForm: FormGroup;
  MutationList:any[]= [];
  selMutationId: string = '';
  mutationFile:any[]=[];;
  filterState: any = {};    
  SurveyJsonID:any;
  private filterStateSubscription!: Subscription;
  constructor(private shareService:ShareService,public ref: DynamicDialogRef,public config: DynamicDialogConfig,private fb: FormBuilder,private panchayatservice: PanchayatService,private filterService: FilterService) {
    this.SurveyJsonID=this.config.data.surveyjsonid;
    this.mutationForm = this.fb.group({
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      aadharCardNumber: ['', [Validators.required, Validators.pattern('^[0-9]{12}$')]],
      applicantName: ['', Validators.required],
      fathersHusbandsName: ['', Validators.required],
      correspondenceAddress: ['', Validators.required],
      emailid: ['', Validators.required],
      mutationCause: ['', Validators.required],
      mutationFee: ['', [Validators.required, Validators.pattern('^[0-9]{5}$')]],
      SaleDeedAmount: ['', [Validators.required, Validators.pattern('^[0-9]{7}$')]],
    });
  }
  loadMutation(){
    this.shareService.setParam(true);
    this.MutationList = [];
    this.panchayatservice.getTaxData("MutationDetails",this.SurveyJsonID).subscribe((data: any) => {
      if(data.length>0){        
        //console.log(data)
        this.MutationList = JSON.parse(data).Mutation;  
      }           
      this.shareService.setParam(false);
    });    
  }
  closedialog():void{
    this.ref.close();
  }
  ngOnInit(): void {
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
     // Update filter state with the changed value
     this.filterState = filterData.filterState; 
     // Call the method in other component to handle the filter changes
     if(this.filterService.isInitialized()){
     //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.loadMutation();
     }
    });                 
  }
  selUploadedDocuments:any[]=[];
  selectMutation(Mutationdata: any): void {    
    this.selUploadedDocuments=[];
    this.selMutationId = Mutationdata.MutationID;
    // Load grievance details if needed
  }
  onFilesChange(files: File[]): void {
    //console.log('Selected files:', files);
  }

  saveMutationData(): void {
    if (this.mutationForm.valid) {
      const formValues = this.mutationForm.value;
      const submissionData = {
        ...formValues,
        grievance_attachment:this.mutationFile.length>0?this.mutationFile[0]:'',
        applicant_location:''
      };            
      console.log(submissionData); 
    // Handle file upload and save status
      // this.panchayatservice.SaveTaxData("UpdGrievanceStatus",JSON.stringify(submissionData)).subscribe((data: any) => {
      //   if(data.includes("Successfully")){
      //     alert(data.GrievanceStatus);
      //     this.loadGrievance();          
      //   }
      // });
    }
    //console.log({ status, remark, file: this.grievanceFile });
  }
  expandedIndex: number | null = null;
  expandedMap: { [key: number]: boolean } = {};
  toggleExpand(assetId: number) {
    this.expandedMap[assetId] = !this.expandedMap[assetId];
  }

  isExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  printDiv(divName: string) {
    let filename ='Tax Slip'+'.pdf';
    let PdfDiv = document.getElementById(divName);
    const content = PdfDiv?.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="http://smilingbrains.com/assets/css/style.css?v=2.0" rel="stylesheet" />');
    printWindow?.document.write(`<style>
    table {
      border-collapse:collapse;
    }
    
    table tr td {
      border: solid black 1px;
      padding: 5px 7px;
    }
    
    table tr th {
      border: none;
      padding-top: 5px;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }  
}

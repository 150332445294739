<div class="row">
    <div class="col-md-12">
        <div class="heading">
            <h4>Asset Lease<small></small></h4>
        </div>
    </div>
    <div class="col-md-12">
      <div id="no-more-tables">
          <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small;font-weight:bold">
              <thead class="cf">
                  <tr>
                      <th>S.No.</th>
                      <th>Ward</th>
                      <th>Mobile No.</th>
                      <th>Aadhar Card</th>
                      <th>Applicant Name</th>
                      <th>Father/Husband Name</th>
                      <th>Address</th>
                      <th>Asset Name</th>
                      <th>Lease Purpose</th>
                      <th>No.of Unit</th>
                      <th>No.of Days</th>
                      <th>Asset Fee</th>
                      <th>Entered On</th>
                  </tr>
              </thead>
              <tbody>
                  <ng-container *ngFor="let asset of lstassetlease; let i = index">
                      <tr (click)="toggleExpand(asset.AssetLeaseid)">
                          <td>{{i + 1}}</td>
                          <td>{{asset.ward}}</td>
                          <td>{{asset.mobileNumber}}</td>
                          <td>{{asset.aadharCardNumber}}</td>
                          <td>{{asset.applicantName}}</td>
                          <td>{{asset.fathersHusbandsName}}</td>
                          <td>{{asset.correspondenceAddress}}</td>
                          <td>{{asset.AssetName}}</td>
                          <td>{{asset.leasepurpose}}</td>
                          <td>{{asset.noofunit}}</td>
                          <td>{{asset.noofdays}}</td>
                          <td>{{asset.AssetFee}}</td>
                          <td>{{asset.enteredon}}</td>
                      </tr>         
                      <tr *ngIf="expandedMap[asset.AssetLeaseid]" class="fold open">
                        <td colspan="7" class="m-srp-card">
                            <div class="row">
                                <div class="col-md-12" style="text-align:right">
                                    <button class="btn btn-warning" (click)="printDiv('dvhousetaxprint')">Print</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" id="dvhousetaxprint">
                                    <div class="row">
                                        <div class="col-md-12" id="dvhousetaxprint">
                                            <table style="font-size: small;font-weight:bold;width:600px;border:1px solid black;">
                                                <tr>
                                                    <td colspan="4" style="text-align:center;">
                                                        <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG" style="width:600px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4" style="text-align:center;">
                                                        <strong> परिसंपत्ति पट्टा रशीद </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2"><qrcode
                                                        qrdata="{{asset.applicantName}} | {{asset.enteredon}} | {{asset.AssetName}} | {{asset.leasepurpose}} | {{asset.noofunit}} | {{asset.noofdays}} | {{asset.AssetFee}}"
                                                        [allowEmptyString]="true"
                                                        [cssClass]="'center'"
                                                        [colorDark]="'#000000ff'"
                                                        [colorLight]="'#ffffffff'"
                                                        [elementType]="'img'"
                                                        [errorCorrectionLevel]="'M'"
                                                        [scale]="1"
                                                        [width]="80"
                                                        ></qrcode></td>
                                                    <td style="text-align:right;" colspan="2">
                                                        <strong>Transaction Number :</strong>AL2024-{{asset.AssetLeaseid}}<br/>                                                                            
                                                        <strong>Date:</strong>{{asset.enteredon}}<br/>                                                                            
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width:25%">
                                                        <b>Name of Owner</b>
                                                    </td>
                                                    <td style="text-align:left;width:25%">
                                                        {{asset.applicantName}}
                                                    </td>
                                                    <td style="width:25%">
                                                        <b>Father/Husband Name</b>
                                                    </td>
                                                    <td style="text-align:left;width:25%">
                                                        {{asset.fathersHusbandsName}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Premisis Address:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{asset.correspondenceAddress}}
                                                    </td>
                                                    <td>
                                                        <b>Ward:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{asset.ward}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Asset Name:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{asset.AssetName}}
                                                    </td>
                                                    <td>
                                                        <b>Purpose of Lease:</b>
                                                    </td>
                                                    <td  style="text-align:left;">{{asset.leasepurpose}}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>No.of Days:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{asset.noofdays}}
                                                    </td>
                                                    <td>
                                                        <b>No.of Unit:</b>
                                                    </td>
                                                    <td  style="text-align:left;">{{asset.noofunit}}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Asset Fee:</b>
                                                    </td>
                                                    <td style="text-align:left;" colspan="3">
                                                        {{asset.AssetFee}}
                                                    </td>
                                                </tr>
                                                  <tr>
                                                    <td colspan="4" align="right">
                                                        <br /><br /><br />
                                                        <b>Executive Officer/ Secretary</b>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>                
                  </ng-container>
              </tbody>
          </table>
      </div>
  </div>
    <div class="col-md-12"> 
        <form [formGroup]="leaseForm" (ngSubmit)="onSubmit()">
            <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="font-red">
                    <!-- Update Data ID : {{selectsForm.controls['UpdateID'].value}} -->
                    </div>
                </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                      <label for="validFrom">Lease From (Date):</label>
                      <input id="validFrom" class="form-control" formControlName="validFrom" type="date">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                    <label for="AssetName">Asset:</label>
                    <input id="AssetName" class="form-control" formControlName="AssetName" type="text">
                    </div>
                </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="applicantName">Applicant Name:</label>
                  <input id="applicantName" class="form-control" formControlName="applicantName" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fathersHusbandsName">Father's/Husband's Name:</label>
                  <input id="fathersHusbandsName" class="form-control" formControlName="fathersHusbandsName" type="text">
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="mobileNumber">Mobile Number:</label>
                        <input id="mobileNumber" class='form-control' formControlName="mobileNumber" type="text"  class='form-control'>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="aadharCardNumber">Aadhar Card Number:</label>
                        <input id="aadharCardNumber" formControlName="aadharCardNumber" type="text"  class='form-control' >
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="leasepurpose">Purpose for Lease:</label>
                  <input id="leasepurpose" class="form-control" formControlName="leasepurpose" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="correspondenceAddress">Correspondence Address:</label>
                  <input id="correspondenceAddress" class="form-control" formControlName="correspondenceAddress" type="text">
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="noofunit">Number of Unit:</label>
                    <input id="noofunit" class="form-control" formControlName="noofunit" type="text">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="noofdays">Number of Days:</label>
                    <input id="noofdays" class="form-control" formControlName="noofdays" type="text">
                  </div>
                </div>
              </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="AssetFee">Asset Fee:</label>
                  <input id="AssetFee" class="form-control" formControlName="AssetFee" type="text">
                </div>
              </div>           
              <div class="col-md-6">
                <div class="form-group">
                  <label for="AssetPhoto">Documents:</label>
                  <app-file-upload [accept]="'image/*'"  [maxFiles]="4" [captionhidestatus]="true" [datehidestatus]="false" [maxSizePerFile]="1048576" [selectedFiles]="assetleaseFile"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button type="submit" class="m-srp-card__btn m-srp-card__btn--primary-o" [disabled]="leaseForm.invalid">Submit</button>
              </div>
            </div>
        </form>
    </div>
</div>
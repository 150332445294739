import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webwardlist',
  templateUrl: './webwardlist.component.html',
  styleUrls: ['./webwardlist.component.css']
})
export class WebWardListComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  } 
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-grievance',
  templateUrl: './grievance.component.html',
  styleUrl: './grievance.component.css'
})

export class GrievanceComponent implements OnInit {
  PanchayatID:any="Manikpur";
  grievanceForm: FormGroup;
  GrievanceMasterList:any[]= [];
  GrievanceList:any[]= [];
  selGrievanceId: string = '';
  grievanceFile:any[]=[];;
  filterState: any = {};
  selviewtype: string="Pending";
  lstprofiletype: any[] = [
    { name: 'Pending', value: 'Pending' },
    { name: 'Resolved', value: 'Resolved' },
    { name: 'Marking', value: 'Marking' }
]; 
  onchangetype():void{
    let seltype=this.selviewtype;
    if(this.selviewtype=='Marking')seltype='Pending';
    this.GrievanceList = this.GrievanceMasterList.filter(item => item.grievance_status === seltype);      
  }
  private filterStateSubscription!: Subscription;
  constructor(private shareService:ShareService,private fb: FormBuilder,private panchayatservice: PanchayatService,private filterService: FilterService) {
    this.grievanceForm = this.fb.group({
      grievance_status: [''],
      grievance_remark: [''],
      grievance_markto:[''],
      grievance_file: ['']
    });
  }
  loadGrievance(){
    this.shareService.setParam(true);
    this.GrievanceMasterList = [];
    this.panchayatservice.getAssetData("GrievanceDetails",this.filterState,false,1,100).subscribe((data: any) => {
      if(data.length>0){        
        //console.log(data)
        this.GrievanceMasterList = JSON.parse(data).GrievanceData;  
        this.GrievanceList = this.GrievanceMasterList.filter(item => item.grievance_status === 'Pending');      
      }           
      this.shareService.setParam(false);
    });    
  }
  ngOnInit(): void {
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
     // Update filter state with the changed value
     this.filterState = filterData.filterState; 
     // Call the method in other component to handle the filter changes
     if(this.filterService.isInitialized()){
     //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.loadGrievance();
     }
    });                 
  }
selGrievance:any;
  selectGrievance(grievancedata: any): void {    
    this.selGrievanceId = grievancedata.grievanceID;
    this.selGrievance=grievancedata;
    // Load grievance details if needed
  }
  onFilesChange(files: File[]): void {
    //console.log('Selected files:', files);
  }

  saveGrievanceStatus(): void {
    const status = this.grievanceForm.get('grievance_status')?.value;
    const remark = this.grievanceForm.get('grievance_remark')?.value;
    if (this.grievanceForm.valid) {
      const formValues = this.grievanceForm.value;
      const submissionData = {
        ...formValues,
        grievanceID:this.selGrievanceId,
        attachments:this.grievanceFile.length>0?this.grievanceFile[0]:'',
        applicant_location:''
      };            
      console.log(submissionData); 
    // Handle file upload and save status
      // this.panchayatservice.SaveTaxData("UpdGrievanceStatus",JSON.stringify(submissionData)).subscribe((data: any) => {
      //   if(data.includes("Successfully")){
      //     alert(data.GrievanceStatus);
      //     this.loadGrievance();          
      //   }
      // });
    }
    //console.log({ status, remark, file: this.grievanceFile });
  }

  saveGrievanceMarking():void{
    const grievance_markto = this.grievanceForm.get('grievance_markto')?.value;
    const submissionData = {
      grievanceID:this.selGrievanceId,
      grievance_markto:grievance_markto
    };
    console.log(JSON.stringify(submissionData)); 
    // Handle file upload and save status
      this.panchayatservice.SaveTaxData("GrievanceMarking",JSON.stringify(submissionData)).subscribe((data: any) => {
        if(data.GrievanceStatus.includes("Successfully")){
          alert(data.GrievanceStatus);
          this.loadGrievance();          
        }
      });
  }
  expandedIndex: number | null = null;
  expandedMap: { [key: number]: boolean } = {};
  toggleExpand(assetId: number) {
    this.expandedMap[assetId] = !this.expandedMap[assetId];
  }

  isExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  printDiv(divName: string) {
    let filename ='Tax Slip'+'.pdf';
    let PdfDiv = document.getElementById(divName);
    const content = PdfDiv?.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="http://smilingbrains.com/assets/css/style.css?v=2.0" rel="stylesheet" />');
    printWindow?.document.write(`<style>
    table {
      border-collapse:collapse;
    }
    
    table tr td {
      border: solid black 1px;
      padding: 5px 7px;
    }
    
    table tr th {
      border: none;
      padding-top: 5px;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }  
}

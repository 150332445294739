import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PanchayatService } from 'src/app/shared/services/panchayat.service';
import { ShareService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-asset-lease',
  templateUrl: './asset-lease.component.html',
  styleUrl: './asset-lease.component.css'
})
export class AssetLeaseComponent implements OnInit {
  PanchayatID:any="Manikpur";
  leaseForm: FormGroup;
  lstLevel2:any[]=[];
  lstassetlease:any[]=[];
  filterState: any = {};
  assetleaseFile:any[]=[];
  private filterStateSubscription!: Subscription;
  constructor(private shareService:ShareService,private fb: FormBuilder,private panchayatservice: PanchayatService,private filterService: FilterService) {        
    this.leaseForm = this.fb.group({
      ward_id: ['', Validators.required],
      validFrom: ['', Validators.required],
      AssetName: ['', Validators.required],
      applicantName: ['', Validators.required],
      fathersHusbandsName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      aadharCardNumber: ['', [Validators.required, Validators.pattern('^[0-9]{12}$')]],
      correspondenceAddress: ['', Validators.required],
      leasepurpose: ['', Validators.required],
      noofunit: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]],
      noofdays: ['', [Validators.required, Validators.pattern('^[0-9]{3}$')]],
      AssetFee: ['', Validators.required],
      AssetPhoto: ['', Validators.required],
      
    });
  }
  ngOnInit(): void {
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
      // Update filter state with the changed value
      this.filterState = filterData.filterState; 
      // Call the method in other component to handle the filter changes
      if(this.filterService.isInitialized()){
      //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
         this.getAssetLeaseData();
      }
     });                 
   }
   onFilesChange(files: File[]): void {
    //console.log('Selected files:', files);
  }
   getAssetLeaseData(): void {
     this.shareService.setParam(true);
     this.lstassetlease = [];
     this.panchayatservice.getAssetData("AssetLeaseDetails",this.filterState,false,1,100).subscribe((data: any) => {
       if(data.length>0){         
         this.lstassetlease = JSON.parse(data).AssetLease;        
       }           
       this.shareService.setParam(false);
     });    
   }
   onSubmit() {
     if (this.leaseForm.valid) {
       console.log(this.leaseForm.value);
       const formValues = this.leaseForm.value;
       const submissionData = {
         ...formValues
       };    
       console.log(submissionData); 
     // Handle file upload and save status
       this.panchayatservice.SaveTaxData("AddAssetLeaseData",JSON.stringify(submissionData)).subscribe((data: any) => {
         if(data.includes("Successfully")){
           alert(data.GrievanceStatus);
           this.getAssetLeaseData();          
         }
       });
     }
   }
   expandedIndex: number | null = null;
  expandedMap: { [key: number]: boolean } = {};
  toggleExpand(assetId: number) {
    this.expandedMap[assetId] = !this.expandedMap[assetId];
  }

  isExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  printDiv(divName: string) {
    let filename ='Tax Slip'+'.pdf';
    let PdfDiv = document.getElementById(divName);
    const content = PdfDiv?.innerHTML;
    const printWindow = window.open('', '', 'width=900,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="http://smilingbrains.com/assets/css/style.css?v=2.0" rel="stylesheet" />');
    printWindow?.document.write(`<style>
    table {
      border-collapse:collapse;
    }
    
    table tr td {
      border: solid black 1px;
      padding: 5px 7px;
    }
    
    table tr th {
      border: none;
      padding-top: 5px;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }  
 }
 
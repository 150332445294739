<div class="row">
    <div class="col-md-12">
        <div class="heading">
            <h4>Trade Licence<small></small></h4>
        </div>
    </div>
    <div class="col-md-12">
      <div id="no-more-tables">
          <table class="fold-table col-md-12 table-bordered table-striped table-condensed cf" id="dtCompliance" style="font-size: small;font-weight:bold">
              <thead class="cf">
                  <tr>
                      <th>S.No.</th>
                      <th>Ward</th>
                      <th>Mobile No.</th>
                      <th>Aadhar Card</th>
                      <th>Applicant Name</th>
                      <th>Father/Husband Name</th>
                      <th>Address</th>
                      <th>Email</th>
                      <th>Occupation</th>
                      <th>Qualification</th>
                      <th>Licence Type</th>
                      <th>Licence Mode</th>
                      <th>Valid From</th>
                      <th>Licence Fee</th>
                      <th>Entered On</th>
                  </tr>
              </thead>
              <tbody>
                  <ng-container *ngFor="let trade of lsttradelicence; let i = index">
                      <tr (click)="toggleExpand(trade.TradeLicenceid)">
                          <td>{{i + 1}}</td>
                          <td>{{trade.ward}}</td>
                          <td>{{trade.mobileNumber}}</td>
                          <td>{{trade.aadharCardNumber}}</td>
                          <td>{{trade.applicantName}}</td>
                          <td>{{trade.fathersHusbandsName}}</td>
                          <td>{{trade.correspondenceAddress}}</td>
                          <td>{{trade.email}}</td>
                          <td>{{trade.occupation}}</td>
                          <td>{{trade.qualification}}</td>
                          <td>{{trade.licenceType}}</td>
                          <td>{{trade.licenceMode}}</td>
                          <td>{{trade.validFrom}}</td>
                          <td>{{trade.licenceFee}}</td>
                          <td>{{trade.enteredon}}</td>
                      </tr>
                      <tr *ngIf="expandedMap[trade.TradeLicenceid]" class="fold open">
                        <td colspan="7" class="m-srp-card">
                            <div class="row">
                                <div class="col-md-12" style="text-align:right">
                                    <button class="btn btn-warning" (click)="printDiv('dvhousetaxprint')">Print</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" id="dvhousetaxprint">
                                    <div class="row">
                                        <div class="col-md-12" id="dvhousetaxprint">
                                            <table style="font-size: small;font-weight:bold;width:600px;border:1px solid black;">
                                                <tr>
                                                    <td colspan="4" style="text-align:center;">
                                                        <img src="../../../../../assets/images/{{PanchayatID}}/formatlogo.JPG" style="width:600px;">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4" style="text-align:center;">
                                                        <strong> व्यापार लाइसेंस रशीद </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2"><qrcode
                                                        qrdata="{{trade.applicantName}} | {{trade.licenceType}} | {{trade.licenceMode}} | {{trade.validFrom}} | {{trade.licenceFee}}"
                                                        [allowEmptyString]="true"
                                                        [cssClass]="'center'"
                                                        [colorDark]="'#000000ff'"
                                                        [colorLight]="'#ffffffff'"
                                                        [elementType]="'img'"
                                                        [errorCorrectionLevel]="'M'"
                                                        [scale]="1"
                                                        [width]="80"
                                                        ></qrcode></td>
                                                    <td style="text-align:right;" colspan="2">
                                                        <strong>Refrence Number :</strong>TL2024-{{trade.TradeLicenceid}}<br/>                                                                            
                                                        <strong>Date:</strong>{{trade.enteredon}}<br/>                                                                            
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width:25%">
                                                        <b>Name of Applicant</b>
                                                    </td>
                                                    <td style="text-align:left;width:25%">
                                                        {{trade.applicantName}}
                                                    </td>
                                                    <td style="width:25%">
                                                        <b>Father/Husband Name</b>
                                                    </td>
                                                    <td style="text-align:left;width:25%">
                                                        {{trade.fathersHusbandsName}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Premisis Address:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{trade.correspondenceAddress}}
                                                    </td>
                                                    <td>
                                                        <b>Ward:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{trade.ward}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                      <b>Mobile:</b>
                                                  </td>
                                                  <td style="text-align:left;">
                                                      {{trade.mobileNumber}}
                                                  </td>
                                                  <td>
                                                      <b>Aadhar Number:</b>
                                                  </td>
                                                  <td style="text-align:left;">
                                                      {{trade.aadharCardNumber}}
                                                  </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                    <b>Licence Type:</b>
                                                </td>
                                                <td style="text-align:left;">
                                                    {{trade.licenceType}}
                                                </td>
                                                <td>
                                                    <b>Licence Mode:</b>
                                                </td>
                                                <td style="text-align:left;">
                                                    {{trade.licenceMode}}
                                                </td>
                                              </tr>
                                                <tr>
                                                    <td>
                                                        <b>Valid From:</b>
                                                    </td>
                                                    <td style="text-align:left;">
                                                        {{trade.validFrom}}
                                                    </td>
                                                    <td>
                                                        <b>Licence Fee:</b>
                                                    </td>
                                                    <td  style="text-align:left;">{{trade.licenceFee}}</td>
                                                </tr>
                                                <tr>
                                                  <td colspan="4" align="right">
                                                      <br /><br /><br />
                                                      <b>Executive Officer/ Secretary</b>
                                                  </td>
                                              </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>                            
                  </ng-container>
              </tbody>
          </table>
      </div>
  </div>
    <div class="col-md-12"> 
        <form [formGroup]="licenseForm" (ngSubmit)="onSubmit()">
            <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="font-red">
                    <!-- Update Data ID : {{selectsForm.controls['UpdateID'].value}} -->
                    </div>
                </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                  <label for="licenceType">Licence Type:</label>
                  <input id="licenceType" class="form-control" formControlName="licenceType" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="licenceMode">Licence Mode:</label>
                  <input id="licenceMode" class="form-control" formControlName="licenceMode" type="text">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="applicantName">Applicant Name:</label>
                  <input id="applicantName" class="form-control" formControlName="applicantName" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fathersHusbandsName">Father's/Husband's Name:</label>
                  <input id="fathersHusbandsName" class="form-control" formControlName="fathersHusbandsName" type="text">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="occupation">Occupation:</label>
                  <input id="occupation" class="form-control" formControlName="occupation" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="qualification">Qualification:</label>
                  <input id="qualification" class="form-control" formControlName="qualification" type="text">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="mobileNumber">Mobile Number:</label>
                  <input id="mobileNumber" class="form-control" formControlName="mobileNumber" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email ID:</label>
                  <input id="email" class="form-control" formControlName="email" type="email">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="aadharCardNumber">Aadhar Card Number:</label>
                  <input id="aadharCardNumber" class="form-control" formControlName="aadharCardNumber" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="correspondenceAddress">Correspondence Address:</label>
                  <input id="correspondenceAddress" class="form-control" formControlName="correspondenceAddress" type="text">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="validFrom">Valid From (Date):</label>
                  <input id="validFrom" class="form-control" formControlName="validFrom" type="date">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="licenceFee">Licence Fee:</label>
                  <input id="licenceFee" class="form-control" formControlName="licenceFee" type="text">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="idProof">Documents:</label>
                  <app-file-upload [accept]="'image/*'"  [maxFiles]="4" [captionhidestatus]="true" [datehidestatus]="false" [maxSizePerFile]="1048576" [selectedFiles]="tradelicenceFile"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button type="submit" class="m-srp-card__btn m-srp-card__btn--primary-o" [disabled]="licenseForm.invalid">Submit</button>
              </div>
            </div>
        </form>
    </div>
</div>
<style>
  #site-header {
    position: fixed;
    _position: absolute;
    top: 0px;
    left: 0;
    height: 55px;
    z-index: 99999;
    width: 100%;
    padding: 0;
    background: rgba(243,113,33,1.00);
    border-bottom: 2px solid #fff;
    box-shadow: 0 -3px 8px #000000;
  }
</style>
<div class="wrapper" id="myDiv1">
  <header class="sticky" id="site-header">
    <div class="container-fluid">
      <div class="row">          
          <div class="col-lg-1 pl-0 pr-0 text-right d-none d-md-block d-md-block">
              <div class="nationalLogo">
                  <a href="#" target="_blank"><img src="assets/images/upgovlogo.png?v=1.5" style="width: 60px;" class="img-fluid"></a>
              </div>
          </div>  <!--ff0075ed-->
          <div class="col-lg-3">
              <h5 style="font-weight: 500; text-shadow:2px 2px 8px purple;"><strong>नगर पंचायत - मानिकपुर</strong> </h5>
              <strong>जनपद - प्रतापगढ़ </strong>
          </div> 
          <div class="col-lg-8">
            <adminnavbar-cmp style="position: fixed;top: 0px;right: 0px;"></adminnavbar-cmp>
          </div>         
      </div>
  </div>
    <!-- <div class="nationalLogo">
      <img src="../../../../../assets/images/upgovlogo.png" style="height:50px;padding-left:1px;" class="img-fluid headlogo"/>
      <p style="display: inline-block;font-size: 14px;vertical-align: top;">Nagar Panchayat -Manikpur</p>           
    </div> -->
    
  </header>
  <!-- END HEADER -->
  <div class="container-fluid" style="margin-top:60px;padding:1px !important;">
    <!-- BEGIN CONTAINER -->
    <div class="page-content" style="background: #f7f7f7;padding: 1px;">
      <!-- BEGIN BREADCRUMBS -->
      <!-- BEGIN PAGE CONTENT INNER -->
      <div class="panel panel-danger" *ngIf="isreport">
        <div class="panel-heading" id="myHeader">					
            <div class="row">
                <div class="col-md-3" style="display: none;">
                    <div class="form-group">
                        <label>Panchayat</label>								
                        <select class='form-control' [(ngModel)]="SelLevel1ID" (change)="getLevel2s();" [disabled]="CheckLoginType('Level1')">
                            <option value="0">Select Panchayat </option>
                            <option value="{{row.Level1_id}}" *ngFor="let row of lstLevel1">{{row.Level1_Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Ward</label>								
                        <select class='form-control' [(ngModel)]="SelLevel2ID" [disabled]="CheckLoginType('Level2')">
                        <option value="0">Select Ward</option>
                        <option value="{{row.Level2_id}}" *ngFor="let row of lstLevel2">{{row.Level2_Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Search by Name/Mobile/Aadhar</label>
                        <input class='form-control' [(ngModel)]="SearchKeyword"/>
                    </div>
                </div>    
                <div class="col-md-3">
                    <div class="form-group"> 
                        <label></label>                   
                        <div class="m-srp-card__action">
                            <div class="m-srp-card__action__item">
                                <a class="m-srp-card__btn m-srp-card__btn--primary-o" (click)="updateAllFilterValue()">
                                    Search
                                </a>
                                <p-progressSpinner *ngIf="issearching" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            </div>
                        </div>                        
                    </div>
                </div>   
            </div>        
        </div>
      </div>
      <!-- END PAGE CONTENT INNER -->
      <!-- END BREADCRUMBS -->
      <!-- BEGIN PAGE CONTENT INNER -->
      <div class="panel panel-danger" style="padding: 0px 20px;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <footer-cmp></footer-cmp>
</div>
